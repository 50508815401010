 <template>
  <q-card id="bottom" class="q-pa-sm">
    <div class="q-mb-sm">
      <div class="title row items-center q-pa-xs text-subtitle1">
        <div>Equiped Story Card</div>
        <q-space />
        <q-btn
          v-if="friend.row.spellCards[spellCardKey] != null"
          icon="mdi-trash-can-outline"
          @click.stop="removeStoryCard"
        />
      </div>
      <q-slide-transition :appear="friend.row.spellCards[spellCardKey] != null">
        <div v-if="friend.row.spellCards[spellCardKey] != null">
          <story-card-item
            :display_toggle="null"
            :story_card="friend.row.spellCards[spellCardKey]"
          ></story-card-item>
        </div>
      </q-slide-transition>
      <div
        v-if="friend.row.spellCards[spellCardKey] == null"
        class="subtitle q-ml-sm text-subtitle2"
      >
        None
      </div>
    </div>
    <q-select
      ref="storyCardQSelect"
      autofocus
      filled
      dark
      options-dense
      popup-content-class="q-pa-none"
      v-model="storyCardModel"
      transition-duration="350"
      label="Equip a Story Card"
      input-debounce="0"
      :options="storyCardOptions"
      @update:model-value="equipStoryCard"
    >
      <template v-slot:option="scope">
        <q-item class="select-option q-pa-none" v-bind="scope.itemProps">
          <q-item-section class="select-option-thumbs col-3">
            <q-img :src="getImgPath(scope.opt.name)" />
          </q-item-section>
          <q-item-section class="q-px-xs col-5">
            <q-item-label lines="1" class="story-card-option-title">
              {{ scope.opt.name }}</q-item-label
            >
          </q-item-section>
          <q-space />
          <q-space />
          <q-item-section class="col-2" no-wrap>
            <q-item-label>Level {{ scope.opt.level }}</q-item-label>
          </q-item-section>
          <q-item-section class="col-1">
            <q-icon
              class="story-card-option-icon"
              :name="
                scope.opt.isMlb
                  ? 'mdi-cards-diamond'
                  : 'mdi-cards-diamond-outline'
              "
            />
          </q-item-section>
        </q-item>
      </template>

      <!-- <template v-slot:no-option>
      <q-item>
        <q-item-section class="text-grey"> No results </q-item-section>
      </q-item>
    </template> -->
    </q-select>
  </q-card>
</template>

<style scoped>
#bottom {
  border-radius: 4px;
  background-color: #203250f2;
  padding-top: 0px;
}
.select-option-thumbs {
  padding: 2px 4px;
}
.title {
  font-size: 1.1rem;
  color: rgba(255, 255, 255, 0.85);
}
.subtitle {
  color: rgba(255, 255, 255, 0.7);
}
</style>

<script setup>
import {
  ref,
  toRefs,
  defineProps,
  defineEmits,
  onMounted,
  computed,
} from "vue";
import { useStore } from "vuex";
import { useQuasar } from "quasar";

import StoryCardItem from "./StoryCardItem.vue";

const myStorage = window.localStorage;
myStorage;
const $q = useQuasar();
$q;

const store = useStore();

const props = defineProps({
  friend: Object,
  editSc: Number,
});
const { friend } = toRefs(props);

const emit = defineEmits(["close"]);

const storyCardItems = ref([]);
storyCardItems.value = store.getters.getStoryCardList;
const storyCardOptions = ref(null);
storyCardOptions.value = storyCardItems.value;
const storyCardModel = ref("");

const storyCardQSelect = ref(null);

const delay = (time) => {
  return new Promise((resolve) => setTimeout(resolve, time));
};

onMounted(() => {
  delay(350).then(() => {
    if (friend.value.row.spellCards[spellCardKey.value] == null) {
      storyCardQSelect.value.showPopup();
    }
  });
});

const getImgPath = (name) => {
  try {
    return require("../assets/story-cards-small-img/".concat(
      name
        .toLowerCase()
        .replaceAll("!", "")
        .replaceAll("?", "")
        .replaceAll("'", "")
        .replaceAll(",", ""),
      ".png"
    ));
  } catch (e) {
    console.log(e);
  }
};

const spellCardKey = computed(() => {
  switch (props.editSc) {
    case 1:
      return "sc1";
    case 2:
      return "sc2";
    case 3:
      return "sc3";
    case 4:
      return "sc4";
    case 5:
      return "lw";
    default:
      return null;
  }
});

const removeStoryCard = () => {
  friend.value.row.spellCards[spellCardKey.value] = null;
  store.commit("updateFriendStoryCards", friend.value.row);
  //console.log(friend.value.row.spellCards);
};

const equipStoryCard = (val) => {
  let sc = {
    row: val,
  };
  friend.value.row.spellCards[spellCardKey.value] = sc;
  store.commit("updateFriendStoryCards", friend.value.row);
  //console.log(friend.value.row.spellCards);
  emit("close");
};
</script>
