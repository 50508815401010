import { createStore } from 'vuex'
import storyCards from './modules/storyCards.js'
import friends from './modules/friends.js'

export default createStore({
  modules: {
    sc: storyCards,
    f: friends,
  }
})

