<template>
   <q-img src="../assets/tlw-logo-budget.png" style="margin-top: ">
    <div class="logo">
      OPTIMIZER
    </div>
   </q-img>
</template>

<script>
export default {
  name: 'Home',
  components: {
  }
}
</script>

<style scoped>
.logo {
  background-color: transparent;
  color: black;
  font-size: 5vw;
  font-family: Verdana;  
  font-weight: 1000;
  position: absolute;
  position: relative;
/*   top: 64%;
  left: 23%; */
  top: 67%;
  left: 45%
}

</style>
