
import './styles/quasar.sass'
import '@quasar/extras/roboto-font/roboto-font.css'
import '@quasar/extras/material-icons/material-icons.css'
import Notify from 'quasar/src/plugins/Notify.js';

// To be used on app.use(Quasar, { ... })
export default {
  config: {
    brand: {
      primary: '#1671cc',
      secondary: '#26A69A',
      background: '#4316cc',
      negative: '#ff3d00',
      dark: '#27292ff2',
      buttons: '#30405c',
    },
  },
  plugins: { Notify },

}