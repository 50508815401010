 <template>
 <div>
   <q-img id="img" src="../assets/marisad-spin.gif"/>  
</div>
  
</template>


<script>
// @ is an alias to /src

export default {
  name: "MarisadSpin",
  components: {    
  },
};
</script>