<template>
  <div class="result-bg row q-pa-xs">
    <q-card class="result-card col-xs-12 col-md-6 col-lg-4 q-pa-sm">
      <div class="row q-col-gutter-sm">
        <div>
          <q-img
            :src="getSpellCardImgPath('sc1')"
            :height="(itemWidth / 4).toString().concat('px')"
            :width="(itemWidth / 8).toString().concat('px')"
          />
        </div>
        <div>
          <q-img
            :src="require('../assets/story-cards-img/dog race.png')"
            :height="(itemWidth / 4).toString().concat('px')"
            :width="(itemWidth / 4 / 0.5305).toString().concat('px')"
          />
          <!--<story-card-item
          :display_toggle="null"
          :story_card="friend.row.spellCards[spellCardKey]"
        ></story-card-item>-->
        </div>
        <div class="col-grow">
          <div class="column fit items-end">
            <div class="text-h5 dmg-text">
              {{ (47289329).toLocaleString("fr") }}
            </div>
            <div class="separator"></div>
            <div class="bonus-text">Yang Atk +3 (1 more turn)</div>
          </div>
        </div>
      </div>
    </q-card>
    <q-card class="result-card col-xs-12 col-md-6 col-lg-4 q-pa-sm">
      <div class="row q-col-gutter-sm">
        <div>
          <q-img
            :src="getSpellCardImgPath('sc2')"
            :height="(itemWidth / 4).toString().concat('px')"
            :width="(itemWidth / 8).toString().concat('px')"
          />
        </div>
        <div>
          <q-img
            :src="
              require('../assets/story-cards-img/shichi-go-san school visit.png')
            "
            :height="(itemWidth / 4).toString().concat('px')"
            :width="(itemWidth / 4 / 0.5305).toString().concat('px')"
          />
          <!--<story-card-item
          :display_toggle="null"
          :story_card="friend.row.spellCards[spellCardKey]"
        ></story-card-item>-->
        </div>
        <div class="col-grow">
          <div class="column fit items-end">
            <div class="text-h5 dmg-text">
              {{ (47191296).toLocaleString("fr") }}
            </div>
            <div class="separator"></div>
            <div class="bonus-text">Yang Atk +3 (1 more turn)</div>
          </div>
        </div>
      </div>
    </q-card>
  </div>
</template>



<style scoped>
.result-card {
  background-color: #25395b;
}
.dmg-text {
  color: #ffffffd0;
}
.bonus-text {
  color: #ffffffd0;
}
.result-bg {
  background-color: #00000020;
}
.separator {
  background-color: #ffffffd0;
  height: 1px;
  width: 90%;
  padding: 0 8px;
  margin: 4px auto;
}
</style>

<script setup>
// @ is an alias to /src
import { onMounted, onUnmounted, ref } from "vue";
import { useStore } from "vuex";
import { useQuasar } from "quasar";

const $q = useQuasar();
const store = useStore();
$q;
store;

const itemWidth = ref(0);
const handleResize = () => {
  try {
    let card = document.getElementsByClassName("result-card")[0];
    itemWidth.value = card.clientWidth;
    //console.log(itemWidth.value);
  } catch (e) {
    console.log("No item to resize");
  }
};

onMounted(() => {
  handleResize();
  window.addEventListener("resize", () => {
    handleResize();
  });
});
onUnmounted(() => {
  window.removeEventListener("resize", () => {
    handleResize();
  });
});

const getSpellCardImgPath = (name) => {
  return require("../assets/spell-cards-thumbs/" +
    "iku nagae" +
    "_" +
    name +
    ".png");
  /*if (friendModel.value != null) {
    return require("../assets/spell-cards-thumbs/" +
      friendModel.value.f.name.toLowerCase() +
      "_" +
      name +
      ".png");
  } else {
    return require("../assets/spell-cards-thumbs/" +
      "placeholder_" +
      name +
      ".png");
  }*/
};
</script>
