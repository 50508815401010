
const myStorage = window.localStorage;

const comparator = (payload) => {
  let a = payload.a
  let b = payload.b
  let sort = payload.sort
  //console.log(payload)
  if ("nameSort" in sort) {
    let nameSort = sort.nameSort
    if (nameSort === "d") {
      if (a.name > b.name) return 1;
      if (a.name < b.name) return -1;
    }
    if (nameSort === "a") {
      if (a.name < b.name) return 1;
      if (a.name > b.name) return -1;
    }
    return 0;
  }
  if ("levelSort" in sort) {
    let levelSort = sort.levelSort
    if (levelSort === "a") return b.level - a.level;
    if (levelSort === "d") return a.level - b.level;
  }
  if ("statSort" in sort && sort.statSort != null) {
    let stat = sort.statSort.toLowerCase()
    return b.stats[stat] - a.stats[stat]
  }
  if ("buffSort" in sort && sort.buffSort != null) {
    let buff = sort.buffSort.toLowerCase()
    return b.buffs[buff] - a.buffs[buff]
  }
  if ("bulletSort" in sort && sort.bulletSort != null) {
    let bullet = sort.bulletSort.toLowerCase()
    return b.bullets[bullet] - a.bullets[bullet]
  }
  return b.id - a.id
}

const loadStoryCardList = () => {
  if (!JSON.parse(myStorage.getItem("story_cards_list")).length) {
    myStorage.setItem("story_cards_list", JSON.stringify([]));
  }
  /*   console.log("__LOAD")
    console.log(JSON.parse(myStorage.getItem("story_cards_list"))) */
  return JSON.parse(
    myStorage.getItem("story_cards_list")
  );
}

const storyCards = {

  state: () => ({
    _calls: {},
    currentId: myStorage.getItem("lastStoryCardId"),
    newStoryCard: {
      id: 0,
      name: "",
      artist: "",
      rarity: 0,
      level: 0,
      isMlb: false,
      desc: "",
      stats: {},
      buffs: {},
      bullets: {},
      displayMode: true, //true => default (name + stats) 
    },
    currentDesc: "",
    currentStats: {},
    currentBuffs: {},
    currentBullets: {},
    currentDisplayMode: true,
    currentArtist: "",
    currentRarity: 0,
    storyCardList: [],
    currentSort: {
      filter: {
        mlbFilter: null,
        statFilter: null,
        buffFilter: null,
        bulletFilter: null,
        rarityFilter: null,
      },
      primary_sort: {},
      secondary_sort: {},
    },
    storyCardData: [],
    isLoadedStoryCardList: false,
    isLoadedStoryCardData: false,
    storyCardsToDelete: [],
    currentToggleDisplayMode: null,
    currentActiveRow: -1,
    editForm: false,
    showForm: false,
  }),
  mutations: {
    _updateCalls(state, payload) {
      let endTime = performance.now()
      if (!state._calls[payload.fn]) state._calls[payload.fn] = {}
      state._calls[payload.fn]["amount"] = (state._calls[payload.fn]["amount"] || 0) + 1
      state._calls[payload.fn]["total_time"] = (state._calls[payload.fn]["total_time"] || 0) + (endTime - payload.startTime)
    },
    incrementStoryCardId(state) {
      myStorage.setItem("lastStoryCardId", ++state.currentId)
    },
    loadStoryCardList(state) {
      state.storyCardList = loadStoryCardList();
    },
    saveStoryCardList(state, payload) {
      /*       console.log("__SAVE")
            console.log(payload) */
      myStorage.setItem("story_cards_list", JSON.stringify(payload))
    },
    setLoadStoryCardList(state, payload) {
      state.isLoadedStoryCardList = payload
    },
    addStoryCard(state, payload) {
      if (!state.isLoadedStoryCardData) {
        console.log("Data not loaded yet!")
      }
      else {
        let tmp = loadStoryCardList();
        let _payload = {
          name: payload.storyCard.name,
          level: payload.storyCard.level,
          isMlb: payload.storyCard.isMlb
        }

        this.commit("calculateStoryCardStats", _payload)
        payload.storyCard.stats = state.currentStats
        this.commit("getScDesc", _payload)
        payload.storyCard.desc = state.currentDesc
        this.commit("getScBuffs", _payload)
        payload.storyCard.buffs = state.currentBuffs
        this.commit("getScBullets", _payload)
        payload.storyCard.bullets = state.currentBullets
        this.commit("getArtist", _payload)
        payload.storyCard.artist = state.currentArtist
        this.commit("getRarity", _payload)
        payload.storyCard.rarity = state.currentRarity
        payload.storyCard.displayMode = true

        for (let i = 0; i < payload.quantity; i++) {
          this.commit("incrementStoryCardId")
          payload.storyCard.id = state.currentId

          let new_sc = JSON.parse(JSON.stringify(payload.storyCard))
          tmp.push(new_sc);
          state.storyCardList.push(new_sc);
        }
        this.commit("saveStoryCardList", tmp);
        this.commit("sortStoryCards", state.currentSort);
      }
    },
    deleteStoryCards(state) {
      state.storyCardsToDelete = []
    },
    restoreStoryCards(state) {
      let tmp = loadStoryCardList();
      tmp = tmp.concat(state.storyCardsToDelete);
      state.storyCardsToDelete = []
      this.commit("saveStoryCardList", tmp);
      this.commit("sortStoryCards", state.currentSort);
    },
    prepareStoryCardDeletion(state, payload) {
      let tmp = loadStoryCardList();
      if (!state.storyCardsToDelete.includes(payload)) {
        tmp = tmp.filter(
          (e) => { return e.id != payload.id });
        state.storyCardList = state.storyCardList.filter(
          (e) => { return e.id != payload.id });
        state.storyCardsToDelete.push(payload);
      }
      this.commit("saveStoryCardList", tmp);
    },
    getStoryCardById(state, payload) {
      let sc = state.storyCardList.find((e) => e.id == payload)
      state.newStoryCard = sc
    },
    updateStoryCard(state, payload) {
      let tmp = loadStoryCardList();
      let _payload = {
        id: payload.id,
        name: payload.name,
        level: payload.level,
        isMlb: payload.isMlb
      }
      this.commit("calculateStoryCardStats", _payload)
      this.commit("getScDesc", _payload)
      this.commit("getScDesc", _payload)
      this.commit("getScBuffs", _payload)
      this.commit("getScBullets", _payload)
      let sc = tmp.find((e) => e.id == payload.id)
      sc.desc = state.currentDesc
      sc.stats = state.currentStats
      sc.buffs = state.currentBuffs
      sc.bullets = state.currentBullets
      sc.level = payload.level
      sc.isMlb = payload.isMlb
      sc.displayMode = payload.displayMode
      this.commit("saveStoryCardList", tmp);
      this.commit("sortStoryCards", state.currentSort);
    },
    calculateStoryCardStats(state, payload) {
      let sc = state.storyCardData.find(
        element => element.name == payload.name)
      try {
        state.currentStats = Object.fromEntries(
          Object.entries(sc.stats).map(
            entry => [entry[0],
            entry[1].base_value + entry[1].increase * payload.level])
        )
      } catch (e) {
        console.log(e)
        state.currentStats = {
          stat: "none",
          value: 0
        }
      }
    },
    getScDesc(state, payload) {
      let sc = state.storyCardData.find(
        (element) => element.name == payload.name
      );
      if (payload.isMlb) {
        state.currentDesc = sc.desc
      }
      else {
        state.currentDesc = sc.base_desc
      }
    },
    getScBuffs(state, payload) {
      let sc = state.storyCardData.find(
        (element) => element.name == payload.name
      );
      try {
        if (payload.isMlb) {
          state.currentBuffs = Object.fromEntries(
            Object.entries(sc.effects.buffs).map(
              entry => [entry[1].stat, entry[1].value])
          )
        }
        else {
          state.currentBuffs = Object.fromEntries(
            Object.entries(sc.base_effects.buffs).map(
              entry => [entry[1].stat, entry[1].value])
          )
        }
      } catch (e) {
        console.log(e)
        state.currentBuffs = {
          stat: "none",
          value: 0
        }
      }
    },
    getScBullets(state, payload) {
      let sc = state.storyCardData.find(
        (element) => element.name == payload.name
      );
      try {
        if (payload.isMlb) {
          state.currentBullets = Object.fromEntries(
            Object.entries(sc.effects.bullets).map(
              entry => [entry[1].type, entry[1].value])
          )
        }
        else {
          state.currentBullets = Object.fromEntries(
            Object.entries(sc.base_effects.bullets).map(
              entry => [entry[1].type, entry[1].value])
          )
        }
      } catch (e) {
        console.log(e)
        state.currentBullets = {
          stat: "none",
          value: 0
        }
      }
    },
    getArtist(state, payload) {
      let sc = state.storyCardData.find(
        (element) => element.name == payload.name
      );
      state.currentArtist = sc.artist
    },
    getRarity(state, payload) {
      let sc = state.storyCardData.find(
        (element) => element.name == payload.name
      );
      state.currentRarity = sc.rarity
    },
    sortStoryCards(state, payload) {
      state.currentSort = payload
      /* console.log("__SORT")
        console.log(JSON.stringify(payload)) */
      state.storyCardList = loadStoryCardList();
      // Filters
      if ("mlbFilter" in payload.filter) {
        this.commit("filterByMLB", payload.filter.mlbFilter)
      }
      if ("statFilter" in payload.filter) {
        this.commit("filterByStat", payload.filter.statFilter)
      }
      if ("buffFilter" in payload.filter) {
        this.commit("filterByBuff", payload.filter.buffFilter)
      }
      if ("bulletFilter" in payload.filter) {
        this.commit("filterByBullet", payload.filter.bulletFilter)
      }
      // Sorts
      let l = state.storyCardList
      l.sort((a, b) => {
        let ps = comparator({ a: a, b: b, sort: payload.primary_sort })
        /*         console.log('Primary')
                console.log(ps) */
        if (ps == 0) {
          let ss = comparator({ a: a, b: b, sort: payload.secondary_sort })
          /*           console.log('_______secondary')
                    console.log(ss) */
          return ss
        }
        else {
          return ps
        }
      })
    },
    filterByMLB(state, payload) {
      let l = state.storyCardList
      if (payload === "MLB") {
        l = l.filter(e => e.isMlb == true)
      }
      else if (payload === "Non MLB") {
        l = l.filter(e => e.isMlb == false)
      }
      else {
        l = l.filter(e => e)
      }
      state.storyCardList = l
    },
    filterByStat(state, payload) {
      let l = state.storyCardList
      if (payload != null) {
        l = l.filter(e => payload.toLowerCase() in e.stats)
      }
      else {
        l = l.filter(e => e)
      }
      state.storyCardList = l
    },
    filterByBuff(state, payload) {
      let l = state.storyCardList
      if (payload != null) {
        l = l.filter(e => payload.toLowerCase() in e.buffs)
      }
      else {
        l = l.filter(e => e)
      }
      state.storyCardList = l
    },
    filterByBullet(state, payload) {
      let l = state.storyCardList
      if (payload != null) {
        l = l.filter(e => payload.toLowerCase() in e.bullets)
      }
      else {
        l = l.filter(e => e)
      }
      state.storyCardList = l
    },
    updateStoryCardDisplayMode(state, payload) {
      this.commit("getStoryCardById", payload.id)
      state.newStoryCard.displayMode = payload.displayMode
    },
    updateAllStoryCardsDisplayMode(state, payload) {
      state.currentToggleDisplayMode = payload
      if (payload != null) {
        myStorage.setItem('display_mode', payload)
        let tmp = loadStoryCardList();
        tmp.map((sc) => sc.displayMode = !payload)
        this.commit("saveStoryCardList", tmp)
        state.storyCardList.map((sc) => sc.displayMode = !payload)
      }
    }
  },
  actions: {
    getStoryCardsData({ state }) {
      let scd = require('../../../db.json').story_cards_data
      state.storyCardData = scd
      state.isLoadedStoryCardData = true; // commit?      
      state.storyCardList = loadStoryCardList();
    },
  },
  modules: {
  },
  getters: {
    getStoryCardData: state => state.storyCardData,
    getNewStoryCard: state => state.newStoryCard,
    getStoryCardList: state => state.storyCardList,
    getSavedStoryCardListLength: () => loadStoryCardList().length,
    getCalls: state => state._calls,
  }
}

export default storyCards;