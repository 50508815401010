<template>
  <div class="q-pa-none">
    <q-card
      class="friend-card"
      @mouseover="mouseHovering"
      @mouseleave="mouseLeft"
      @click="clicked"
      @mouseup="mouseUp"
    >
      <div v-if="hovered && active" class="buttons-div" on-right>
        <q-btn
          name="update"
          icon="mdi-pencil-box-outline"
          class="buttons"
          color="secondary"
          @mousedown.stop="editFriend = true"
        >
        </q-btn>
        <q-btn
          name="delete"
          icon="mdi-trash-can-outline"
          class="buttons"
          color="negative"
          @mousedown.stop="showDeleteNotif"
        >
        </q-btn>
      </div>
      <div
        id="friend-card-title"
        class="text-h6 q-py-xs q-px-sm no-wrap ellipsis"
      >
        {{ friend.row.name }}
      </div>
      <div v-if="friend.row.displayMode" class="row">
        <div class="friend-card-left col-4">
          <div id="friend-img" class="q-px-sm">
            <q-img
              :width="(itemWidth / 3.6).toString().concat('px')"
              :src="smallImgPath"
            >
              <div
                v-if="friend.row.isFr"
                id="fr-icon"
                class="absolute-top-right"
              >
                <q-img
                  class="q-pa-sm"
                  :width="(itemWidth / 14).toString().concat('px')"
                  :src="require('../assets/re-icon.png')"
                />
              </div>
            </q-img>
          </div>
          <div id="rating">
            <q-rating
              class="q-mt-xs q-ml-xs"
              :model-value="friend.row.awoken"
              size="1.45em"
              color-selected="primary"
              color="blue-10"
              readonly
            />
          </div>
          <div class="q-pl-sm q-pb-sm">
            Lvl. <span id="level-text">{{ friend.row.level }}</span
            ><span id="maxlevel-text">/{{ friend.row.maxLevel }} </span>
          </div>
        </div>
        <div class="friend-card-right col-grow column justify-between">
          <div class="row justify-between">
            <div class="stat-icon no-wrap row col q-mr-xs">
              <q-tooltip
                class="bg-info text-body2"
                transition-show="fade"
                transition-hide="fade"
                :delay="100"
                :hide-delay="200"
                :offset="[0, 1]"
              >
                Health
              </q-tooltip>
              <q-img :src="require('../assets/stats-icons/health.png')">
              </q-img>
              <div class="q-pl-sm">
                {{ friend.row.stats.health }}
              </div>
            </div>
            <div class="stat-icon no-wrap row col q-mr-xs">
              <q-tooltip
                class="bg-info text-body2"
                transition-show="fade"
                transition-hide="fade"
                :delay="150"
                :hide-delay="250"
                :offset="[0, 1]"
              >
                Agility
              </q-tooltip>
              <q-img :src="require('../assets/stats-icons/agility.png')">
              </q-img>
              <div class="q-pl-sm">
                {{ friend.row.stats.agility }}
              </div>
            </div>
          </div>
          <div class="row justify-between">
            <div class="stat-icon no-wrap row col q-mr-xs">
              <q-tooltip
                class="bg-info text-body2"
                transition-show="fade"
                transition-hide="fade"
                :delay="150"
                :hide-delay="250"
                :offset="[0, 1]"
              >
                Yang ATK
              </q-tooltip>
              <q-img :src="require('../assets/stats-icons/yang atk.png')">
              </q-img>
              <div class="q-pl-sm">
                {{ friend.row.stats.yangAtk }}
              </div>
            </div>
            <div class="stat-icon no-wrap row col q-mr-xs">
              <q-tooltip
                class="bg-info text-body2"
                transition-show="fade"
                transition-hide="fade"
                :delay="350"
                :hide-delay="50"
                :offset="[0, 1]"
              >
                Yin ATK
              </q-tooltip>
              <q-img :src="require('../assets/stats-icons/yin atk.png')">
              </q-img>
              <div class="q-pl-sm">
                {{ friend.row.stats.yinAtk }}
              </div>
            </div>
          </div>
          <div class="row justify-between">
            <div class="stat-icon no-wrap row col q-mr-xs">
              <q-tooltip
                class="bg-info text-body2"
                transition-show="fade"
                transition-hide="fade"
                :delay="350"
                :hide-delay="50"
                :offset="[0, 1]"
              >
                Yang DEF
              </q-tooltip>
              <q-img :src="require('../assets/stats-icons/yang def.png')">
              </q-img>
              <div class="q-pl-sm">
                {{ friend.row.stats.yangDef }}
              </div>
            </div>
            <div class="stat-icon no-wrap row col q-mr-xs">
              <q-tooltip
                class="bg-info text-body2"
                transition-show="fade"
                transition-hide="fade"
                :delay="350"
                :hide-delay="50"
                :offset="[0, 1]"
              >
                Yin DEF
              </q-tooltip>
              <q-img :src="require('../assets/stats-icons/yin def.png')">
              </q-img>
              <div class="q-pl-sm">
                {{ friend.row.stats.yinDef }}
              </div>
            </div>
          </div>
        </div>
      </div>
      <div v-else class="row q-pa-xs q-gutter-xs">
        <q-btn
          class="spell-card-btn q-pa-none"
          @click.stop="
            editSpellCard = true;
            editSc = 1;
          "
        >
          <q-img :src="getSpellCardImgPath('sc1')" :ratio="0.5"
            ><q-img
              class="spell-cards-story-card absolute-bottom"
              :src="getStoryCardImgPath(friend.row.spellCards.sc1)"
            />
          </q-img>
        </q-btn>
        <q-btn
          class="spell-card-btn q-pa-none"
          @click.stop="
            editSpellCard = true;
            editSc = 2;
          "
        >
          <q-img :src="getSpellCardImgPath('sc2')" :ratio="0.5"
            ><q-img
              class="spell-cards-story-card absolute-bottom"
              :src="getStoryCardImgPath(friend.row.spellCards.sc2)"
            />
          </q-img>
        </q-btn>
        <q-btn
          class="spell-card-btn q-pa-none"
          @click.stop="
            editSpellCard = true;
            editSc = 3;
          "
          :disable="friend.row.maxLevel < 80"
        >
          <q-img :src="getSpellCardImgPath('sc1')" :ratio="0.5"
            ><q-img
              class="spell-cards-story-card absolute-bottom"
              :src="getStoryCardImgPath(friend.row.spellCards.sc3)"
            />
          </q-img>
        </q-btn>
        <q-btn
          class="spell-card-btn q-pa-none"
          @click.stop="
            editSpellCard = true;
            editSc = 4;
          "
          :disable="friend.row.maxLevel < 90"
        >
          <q-img :src="getSpellCardImgPath('sc2')" :ratio="0.5"
            ><q-img
              class="spell-cards-story-card absolute-bottom"
              :src="getStoryCardImgPath(friend.row.spellCards.sc4)"
            />
          </q-img>
        </q-btn>
        <q-btn
          class="spell-card-btn q-pa-none"
          @click.stop="
            editSpellCard = true;
            editSc = 5;
          "
          :disable="friend.row.maxLevel < 100"
        >
          <q-img :src="getSpellCardImgPath('lw')" :ratio="0.5"
            ><q-img
              class="spell-cards-story-card absolute-bottom"
              :src="getStoryCardImgPath(friend.row.spellCards.lw)"
            />
          </q-img>
        </q-btn>
      </div>
      <q-dialog v-model="editFriend">
        <q-card id="form-dialog" class="q-px-sm q-pb-sm">
          <friends-form :friend="friend"></friends-form>
        </q-card>
      </q-dialog>
      <q-dialog v-model="editSpellCard">
        <q-card id="form-dialog">
          <story-card-select
            :friend="friend"
            :editSc="editSc"
            v-on:close="closeScSelect"
          ></story-card-select>
        </q-card>
      </q-dialog>
    </q-card>
  </div>
</template>

<style scoped>
#friend-card-title {
  font-size: 1.2em;
}
#fr-icon {
  background-color: #ffffff00;
  padding: 3px;
}
#form-dialog {
  background-color: #203250f2;
  width: min(560px, 90vw);
  margin: 0px 0px;
}
#level-text {
  font-size: 1.1em;
  font-weight: 500;
}
#maxlevel-text {
  color: #ffffffb3;
}
.spell-card-btn {
  width: calc(20% - 4px);
}
.friend-card-right {
  padding: 2px 8px 12px 4px;
}
.stat-icon {
  font-size: 1.2em;
  padding: 3px;
  background: linear-gradient(
    120deg,
    rgba(22, 113, 204, 1) 0%,
    rgba(31, 81, 138, 1) 40%,
    rgba(37, 57, 91, 1) 80%
  );
  border-radius: 4px;
  box-shadow: 0 2px 6px #203250f2;
}
.stat-icon .q-img {
  height: 1.5rem;
  width: 1.5rem;
}
.friend-card {
  background-color: #25395b;
}
.buttons-div {
  position: absolute;
  padding: 0;
  right: 2px;
  top: 5px;
  background-color: transparent;
  z-index: 1;
}
.buttons {
  padding: 10px 10px;
  margin: 0px 3px;
}
.spell-cards-story-card {
  width: 92%;
  height: 28%;
  padding: 0;
  margin: 4%;
}
</style>

<script setup>
import { defineProps, watch } from "vue";
import { toRefs, ref, computed } from "@vue/reactivity";
import { useStore } from "vuex";
import { onMounted, onUnmounted, onUpdated } from "@vue/runtime-core";
import { useQuasar } from "quasar";

import FriendsForm from "./FriendsForm.vue";
import StoryCardSelect from "./StoryCardSelect.vue";

const store = useStore();
const $q = useQuasar();

const editFriend = ref(false);

const closeScSelect = () => {
  editSpellCard.value = false;
};

const props = defineProps({
  friend: Object,
  display_toggle: Object,
});
const { friend } = toRefs(props);
const { display_toggle } = toRefs(props);

const smallImgPath = ref("");

const hovered = ref(false);

const restore = ref(false);

const itemWidth = ref(0);

const editSpellCard = ref(false);
const editSc = ref(1);

const handleResize = () => {
  try {
    let card = document.getElementsByClassName("friend-card")[0];
    itemWidth.value = card.clientWidth;
    //console.log(itemWidth.value);
  } catch (e) {
    console.log("No item to resize");
  }
};

const mouseHovering = () => {
  if (!(navigator.maxTouchPoints >= 1)) {
    //console.log("hover");
    hovered.value = true;
    store.state.f.currentActiveRow = friend.value.rowIndex;
  }
};
const mouseLeft = () => {
  //console.log("left");
  hovered.value = false;
  store.state.f.currentActiveRow = -1;
};
const mouseUp = () => {
  if (navigator.maxTouchPoints >= 1) {
    //console.log("up");
    hovered.value = true;
    store.state.f.currentActiveRow = friend.value.rowIndex;
  }
};
const clicked = () => {
  friend.value.row.displayMode = !friend.value.row.displayMode;
  let payload = {
    id: friend.value.row.id,
    displayMode: friend.value.row.displayMode,
  };
  display_toggle.value.displayMode = null;
  store.commit("updateAllFriendsDisplayMode", null);
  store.commit("updateFriendDisplayMode", payload);
};

const active = computed(() => {
  return friend.value.rowIndex == store.state.f.currentActiveRow;
});

onMounted(() => {
  handleResize();
  window.addEventListener("resize", () => {
    handleResize();
  });
});
onUnmounted(() => {
  window.removeEventListener("resize", () => {
    handleResize();
  });
});

const getFriendImgPath = () => {
  try {
    smallImgPath.value = require("../assets/friends-thumbs/".concat(
      friend.value.row.name
        .toLowerCase()
        .replaceAll("!", "")
        .replaceAll("?", "")
        .replaceAll("'", "")
        .replaceAll(",", ""),
      ".png"
    ));
  } catch (e) {
    console.log(e);
  }
};

const getSpellCardImgPath = (name) => {
  return require("../assets/spell-cards-thumbs/" +
    friend.value.row.name.toLowerCase() +
    "_" +
    name +
    ".png");
};

onMounted(getFriendImgPath);
onUpdated(getFriendImgPath);

const getStoryCardImgPath = (sc) => {
  if (sc) {
    try {
      return require("../assets/story-cards-small-img/".concat(
        sc.row.name
          .toLowerCase()
          .replaceAll("!", "")
          .replaceAll("?", "")
          .replaceAll("'", "")
          .replaceAll(",", ""),
        ".png"
      ));
    } catch (e) {
      console.log(e);
    }
  }
};

watch(editFriend, (newVal) => {
  if (newVal) {
    store.state.f.editForm = editFriend;
  }
});

const deleteFriend = () => {
  store.commit("deleteFriends");
};

const restoreFriend = () => {
  store.commit("restoreFriends");
};

const showDeleteNotif = () => {
  let deleteCount = store.state.f.friendsToDelete.length + 1;
  store.commit("prepareFriendDeletion", friend.value.row);
  $q.notify({
    progress: true,
    group: "deleteNotif",
    type: "negative",
    message:
      deleteCount >= 2
        ? `${deleteCount} Friends deleted`
        : `${deleteCount} Friend deleted`,
    textColor: "white",
    multiLine: true,
    timeout: 4400,
    onDismiss: () => {
      if (!restore.value) deleteFriend();
      restore.value = false;
    },
    actions: [
      {
        label: deleteCount >= 2 ? "Restore All" : "Restore",
        color: "white",
        handler: () => {
          restore.value = true;
          restoreFriend();
        },
      },
      {
        label: "Confirm",
        color: "white",
        handler: () => {
          deleteFriend();
        },
      },
    ],
  });
};
</script> 