<template>
  <q-form
    @submit="onSubmit"
    class="q-mt-sm q-mb-xs q-gutter-y-sm"
    align="center"
  >
    <q-select
      v-if="!edit"
      class="q-mb-md"
      autofocus
      name="friendSelect"
      filled
      dark
      v-model="friendModel"
      use-input
      hide-selected
      hide-bottom-space
      fill-input
      label="Pick a Friend"
      input-debounce="0"
      :options="friendOptions"
      @filter="friendFilter"
      @input-value="setFriend"
      :rules="[(val) => friendOptions.includes(val)]"
      error-message="Please enter a valid friend name"
    >
      <template v-slot:option="scope">
        <q-item class="select-option" v-bind="scope.itemProps">
          <q-item-section class="select-option-icon" avatar>
            <q-img id="select-friend-img" :src="scope.opt.imgPath" />
          </q-item-section>
          <q-space />
          <q-item-section>
            <q-item-label>{{ scope.opt.label }}</q-item-label>
          </q-item-section>
        </q-item>
      </template>

      <template v-slot:no-option>
        <q-item>
          <q-item-section class="text-grey"> No results </q-item-section>
        </q-item>
      </template>
    </q-select>

    <div class="row q-mb-md">
      <q-input
        class="col"
        name="level"
        v-model.number="level"
        hide-bottom-space
        type="number"
        label="Level"
        filled
        dark
        reactive-rules
        @update:model-value="setLevel"
        :rules="[(val) => val > 0 && val <= maxLevel]"
        :error="((val) => val > 0 && val <= maxLevel) ? false : true"
        error-message="Please enter a number between 1 and Max. Level"
        min="1"
        :max="maxLevel"
      />
      <div id="levels-separator" class="text-h6 q-mx-xs q-mt-md">/</div>
      <q-select
        class="col"
        name="maxLevel"
        filled
        dark
        v-model="maxLevel"
        hide-bottom-space
        label="Max. Level"
        :options="maxLevelOptions"
        @update:model-value="setMaxLevel"
        :readonly="frToggle == 'fr'"
        :rules="[(val) => maxLevelOptions.includes(val)]"
        error-message="Please enter a valid number"
      >
        <template v-slot:no-option>
          <q-item>
            <q-item-section class="text-grey"> No results </q-item-section>
          </q-item>
        </template>
      </q-select>
    </div>
    <div dark class="row q-gutter-y-md">
      <div class="row items-center col-grow q-mr-sm">
        <div class="form-subtitle">Rank</div>
        <q-btn-toggle
          class="rank-toggle"
          name="rankToggle"
          v-model="rankToggle"
          unelevated
          no-caps
          padding="6px 12px"
          toggle-color="primary"
          text-color="white"
          @update:model-value="setRank"
          :readonly="frToggle == 'fr'"
          :options="[
            { label: '0', value: 0 },
            { label: '1', value: 1 },
            { label: '2', value: 2 },
            { label: '3', value: 3 },
            { label: '4', value: 4 },
            { label: '5', value: 5 },
          ]"
        />
      </div>
      <div class="row items-center col-grow q-mr-sm">
        <div class="form-subtitle">Awaken</div>
        <q-rating
          v-model="awoken"
          size="2em"
          color="primary"
          @update:model-value="setAwoken"
          :readonly="frToggle == 'fr'"
        />
      </div>
      <div class="row items-center col-grow q-mr-sm">
        <div class="form-subtitle">Friendship</div>
        <q-btn-toggle
          class="friendship-toggle"
          name="friendshipToggle"
          v-model="friendshipToggle"
          unelevated
          no-caps
          padding="5px 5px 4px 5px"
          toggle-color="primary"
          @update:model-value="setFriendship"
          :options="[
            { slot: 'purple', value: -1 },
            { slot: 'blue', value: 0 },
            { slot: 'green', value: 1 },
            { slot: 'orange', value: 2 },
            { slot: 'pink', value: 3 },
          ]"
          ><template v-slot:purple>
            <q-icon color="deep-purple-4" name="mdi-heart" />
          </template>
          <template v-slot:blue>
            <div class="row items-center no-wrap">
              <q-icon color="blue-11" name="mdi-heart" />
            </div>
          </template>
          <template v-slot:green>
            <div class="row items-center no-wrap">
              <q-icon color="light-green-13" name="mdi-heart" />
            </div>
          </template>
          <template v-slot:orange>
            <div class="row items-center no-wrap">
              <q-icon color="orange-12" name="mdi-heart" />
            </div>
          </template>
          <template v-slot:pink>
            <div class="row items-center no-wrap">
              <q-icon color="pink-11" name="mdi-heart" />
            </div>
          </template>
        </q-btn-toggle>
      </div>
      <div class="row items-center col-grow">
        <q-btn-toggle
          name="frToggle"
          class="fr-toggle"
          v-model="frToggle"
          no-caps
          toggle-color="primary"
          text-color="white"
          @update:model-value="setValues"
          :disable="!canFr"
          :options="[
            { label: '⠀⠀⠀Normal⠀⠀⠀', value: 'normal' },
            { label: 'Fantasy Rebirth', value: 'fr' },
          ]"
        />
      </div>
    </div>

    <q-expansion-item
      v-model="statsExpanded"
      @update:model-value="saveStatsExpanded"
      dark
      switch-toggle-side
      expand-icon-toggle
    >
      <template v-slot:header>
        <div class="row content-center items-center">
          <div class="stats-subtitle">Stats</div>
          <q-btn
            class="help-btn"
            round
            flat
            icon="mdi-help-circle-outline"
            size="10px"
          >
            <q-tooltip
              class="bg-transparent text-body2 q-mr-sm"
              anchor="top left"
              self="bottom left"
              max-width="90%"
              :hide-delay="500"
              transition-show="scale"
              transition-hide="scale"
            >
              <div class="help-tooltip q-pa-sm">
                The automatic calculation of stats might not be 100%
                accurate.<br />
                Edit them to match your friend's actual stats.
              </div>
            </q-tooltip>
          </q-btn>
        </div>
      </template>
      <div class="stat-inputs row q-mb-sm q-ml-md q-col-gutter-sm">
        <q-input
          class="col-xs-6 col-sm-4 col-lg-2 col-grow"
          name="health"
          v-model.number="stats.health"
          type="number"
          label="Health"
          filled
          dark
          hide-bottom-space
          no-error-icon
          @update:model-value="setHealth"
          :rules="[(val) => val > 0 && val <= MAX_HEALTH]"
          error-message="Please enter a number between 1 and 9999"
          min="1"
          max="MAX_HEALTH"
        >
          <template v-slot:prepend>
            <q-btn
              @click="
                () => {
                  --stats.health;
                  setHealth();
                }
              "
              class="decrement-btn"
              icon="mdi-minus"
              color="primary"
            />
          </template>
          <template v-slot:append>
            <q-btn
              @click="
                () => {
                  ++stats.health;
                  setHealth();
                }
              "
              class="increment-btn"
              icon="mdi-plus"
              color="primary"
            />
          </template>
        </q-input>
        <q-input
          class="col-xs-6 col-sm-4 col-lg-2 col-grow"
          name="yang atk"
          v-model.number="stats.yangAtk"
          type="number"
          label="Yang ATK"
          filled
          dark
          hide-bottom-space
          no-error-icon
          @update:model-value="setYangAtk"
          :rules="[(val) => val > 0 && val <= MAX_STAT]"
          error-message="Please enter a number between 1 and 9999"
          min="1"
          max="MAX_STAT"
        >
          <template v-slot:prepend>
            <q-btn
              @click="
                () => {
                  --stats.yangAtk;
                  setYangAtk();
                }
              "
              class="decrement-btn"
              icon="mdi-minus"
              color="primary"
            />
          </template>
          <template v-slot:append>
            <q-btn
              @click="
                () => {
                  ++stats.yangAtk;
                  setYangAtk();
                }
              "
              class="increment-btn"
              icon="mdi-plus"
              color="primary"
            />
          </template>
        </q-input>
        <q-input
          class="col-xs-6 col-sm-4 col-lg-2 col-grow"
          name="yin atk"
          v-model.number="stats.yinAtk"
          type="number"
          label="Yin ATK"
          filled
          dark
          hide-bottom-space
          no-error-icon
          @update:model-value="setYinAtk"
          :rules="[(val) => val > 0 && val <= MAX_STAT]"
          error-message="Please enter a number between 1 and 9999"
          min="1"
          max="MAX_STAT"
        >
          <template v-slot:prepend>
            <q-btn
              @click="
                () => {
                  --stats.yinAtk;
                  setYinAtk();
                }
              "
              class="decrement-btn"
              icon="mdi-minus"
              color="primary"
            />
          </template>
          <template v-slot:append>
            <q-btn
              @click="
                () => {
                  ++stats.yinAtk;
                  setYinAtk();
                }
              "
              class="increment-btn"
              icon="mdi-plus"
              color="primary"
            />
          </template>
        </q-input>
        <q-input
          class="col-xs-6 col-sm-4 col-lg-2 col-grow"
          name="agility"
          v-model.number="stats.agility"
          type="number"
          label="Agility"
          filled
          dark
          hide-bottom-space
          no-error-icon
          @update:model-value="setAgility"
          :rules="[(val) => val > 0 && val <= MAX_STAT]"
          error-message="Please enter a number between 1 and 9999"
          min="1"
          max="MAX_STAT"
        >
          <template v-slot:prepend>
            <q-btn
              @click="
                () => {
                  --stats.agility;
                  setAgility();
                }
              "
              class="decrement-btn"
              icon="mdi-minus"
              color="primary"
            />
          </template>
          <template v-slot:append>
            <q-btn
              @click="
                () => {
                  ++stats.agility;
                  setAgility();
                }
              "
              class="increment-btn"
              icon="mdi-plus"
              color="primary"
            />
          </template>
        </q-input>
        <q-input
          class="col-xs-6 col-sm-4 col-lg-2 col-grow"
          name="yang def"
          v-model.number="stats.yangDef"
          type="number"
          label="Yang DEF"
          filled
          dark
          hide-bottom-space
          no-error-icon
          @update:model-value="setYangDef"
          :rules="[(val) => val > 0 && val <= MAX_STAT]"
          error-message="Please enter a number between 1 and 9999"
          min="1"
          max="MAX_STAT"
        >
          <template v-slot:prepend>
            <q-btn
              @click="
                () => {
                  --stats.yangDef;
                  setYangDef();
                }
              "
              class="decrement-btn"
              icon="mdi-minus"
              color="primary"
            />
          </template>
          <template v-slot:append>
            <q-btn
              @click="
                () => {
                  ++stats.yangDef;
                  setYangDef();
                }
              "
              class="increment-btn"
              icon="mdi-plus"
              color="primary"
            />
          </template>
        </q-input>
        <q-input
          class="col-xs-6 col-sm-4 col-lg-2 col-grow"
          name="yin def"
          v-model.number="stats.yinDef"
          type="number"
          label="Yin DEF"
          filled
          dark
          hide-bottom-space
          no-error-icon
          @update:model-value="setYinDef"
          :rules="[(val) => val > 0 && val <= MAX_STAT]"
          error-message="Please enter a number between 1 and 9999"
          min="1"
          max="MAX_STAT"
        >
          <template v-slot:prepend>
            <q-btn
              @click="
                () => {
                  --stats.yinDef;
                  setYinDef();
                }
              "
              class="decrement-btn"
              icon="mdi-minus"
              color="primary"
            />
          </template>
          <template v-slot:append>
            <q-btn
              @click="
                () => {
                  ++stats.yinDef;
                  setYinDef();
                }
              "
              class="increment-btn"
              icon="mdi-plus"
              color="primary"
            />
          </template>
        </q-input>
      </div>
    </q-expansion-item>
    <div v-if="!edit" align="center">
      <q-btn
        label="Submit"
        type="submit"
        color="primary"
        @click="showAddNotif"
        class="q-mr-md"
      />
      <q-btn
        @click="$emit('close', false)"
        label="Cancel"
        color="white"
        flat
        class="q-ml-md"
      />
    </div>
    <div v-else align="center">
      <q-btn
        label="Confirm"
        type="submit"
        color="primary"
        v-close-popup
        @click="showUpdateNotif"
      />
    </div>
  </q-form>
</template>

<style scoped>
#select-friend-img {
  height: 45px;
  width: 45px;
}
#levels-separator {
  color: rgba(255, 255, 255, 0.7);
  font-size: 2em;
  font-weight: 300;
}
.select-option {
  padding: 0px 4px;
}
.select-option-icon {
  padding: 0px;
  margin-right: 12px;
}
.stats-subtitle {
  font-size: 1.3em;
  margin-left: -20px;
  margin-right: 4px;
}
.help-btn {
  color: rgba(255, 255, 255, 0.85);
}
.help-tooltip {
  background-color: #027ae3d0;
}
.form-subtitle {
  color: rgba(255, 255, 255, 1);
  font-size: 1.3em;
  margin-right: 12px;
}
.fr-toggle {
  background-color: #30405c;
}
.max-toggle {
  background-color: #30405c;
}
.rank-toggle {
  background-color: #30405c;
}
.friendship-toggle {
  background-color: #30405c;
}
.q-expansion-item {
  margin-left: -16px;
  margin-bottom: 16px;
}
.decrement-btn {
  height: 100%;
  width: 15px;
  padding: auto;
  margin-left: -12px;
}
.increment-btn {
  height: 100%;
  width: 15px;
  padding: auto;
  margin-right: -12px;
}
.q-expansion-item .q-input{
  min-width: 135px;
}
</style>

<script setup>
// @ is an alias to /src
import { defineProps, onMounted, ref, reactive, toRefs } from "vue";
import { useStore } from "vuex";
import { useQuasar } from "quasar";

const $q = useQuasar();
const store = useStore();

const props = defineProps({
  friend: Object,
});
const { friend } = toRefs(props);
const edit = friend.value == null ? false : true;

const MAX_HEALTH = 19999;
const MAX_STAT = 9999;

const friendSelect = ref(null);
const friendsObj = ref(null);
const friendOptions = ref(null);
const friendItems = ref([]);
const friendModel = ref("");

const level = ref(100);

const maxLevelOptions = ref([70, 80, 90, 100]);
const maxLevel = ref(100);

const stats = reactive({
  health: 1,
  yangAtk: 1,
  yinAtk: 1,
  agility: 1,
  yangDef: 1,
  yinDef: 1,
});

const rankToggle = ref(5);
const awoken = ref(5);
const friendshipToggle = ref(3);

const frToggle = ref("normal");
const canFr = ref(false);

const statsExpanded = ref(store.state.f.statsExpanded);

const getfriendItems = () => {
  if (!store.state.f.isLoadedFriendData) {
    friendsObj.value = store.dispatch("getFriendsData");
  } else friendsObj.value = store.getters.getFriendData;

  for (const [key, value] of Object.entries(friendsObj.value)) {
    key;
    let dict = {
      label: value.name,
      imgPath: getImgPath(value.name),
      baseStats: {
        health: value.stats.health,
        yangAtk: value.stats.yang_atk,
        yinAtk: value.stats.yin_atk,
        agility: value.stats.agility,
        yangDef: value.stats.yang_def,
        yinDef: value.stats.yin_def,
      },
    };
    if (value.fr_stats) {
      dict["frStats"] = {
        health: value.fr_stats.health,
        yangAtk: value.fr_stats.yang_atk,
        yinAtk: value.fr_stats.yin_atk,
        agility: value.fr_stats.agility,
        yangDef: value.fr_stats.yang_def,
        yinDef: value.fr_stats.yin_def,
      };
    }
    friendItems.value.push(dict);
  }
  friendOptions.value = friendItems.value;
  
  if (edit) {
    level.value = friend.value.row.level;
    maxLevel.value = friend.value.row.maxLevel;
    rankToggle.value = friend.value.row.rank;
    awoken.value = friend.value.row.awoken;
    friendshipToggle.value = friend.value.row.friendship;
    frToggle.value = friend.value.row.isFr == true ? "fr" : "normal";
    stats.health = friend.value.row.stats.health
    stats.yangAtk = friend.value.row.stats.yangAtk
    stats.yinAtk = friend.value.row.stats.yinAtk
    stats.agility = friend.value.row.stats.agility
    stats.yangDef = friend.value.row.stats.yangDef
    stats.yinDef = friend.value.row.stats.yinDef
  }
};
onMounted(getfriendItems);

const getImgPath = (name) => {
  try {
    return require("../assets/friends-thumbs/".concat(
      name
        .toLowerCase()
        .replaceAll("!", "")
        .replaceAll("?", "")
        .replaceAll("'", "")
        .replaceAll(",", ""),
      ".png"
    ));
  } catch (e) {
    console.log(e);
  }
};

const friendFilter = (val, update) => {
  setTimeout(() => {
    update(
      () => {
        if (val === "") {
          friendOptions.value = friendItems.value;
        } else {
          const needle = val.toLocaleLowerCase();
          friendOptions.value = friendItems.value.filter(
            (v) => v.label.toLocaleLowerCase().indexOf(needle) > -1
          );
        }
      },
      (ref) => {
        if (val !== "" && ref.options.length > 0) {
          ref.setOptionIndex(-1); // reset optionIndex in case there is something selected
          ref.moveOptionSelection(1, true); // focus the first selectable option and do not update the input-value
        }
      }
    );
  }, 200);
};

const setFriend = (val) => {
  friendSelect.value = val;
  setStats(val);
};

const setStats = (val) => {
  let selected;
  if (!edit) {
    selected = friendOptions.value.find((v) => v.label == val);
  } else {
    selected = friendOptions.value.find(
      (v) => v.label == friend.value.row.name
    );
  }
  if (selected) {
    selected.frStats ? (canFr.value = true) : (canFr.value = false);
    let stats_ = {};
    if (frToggle.value == "fr" && selected.frStats) {
      stats_ = selected.frStats;
    } else {
      stats_ = selected.baseStats;
    }
    //console.log(stats_);
    stats.health = calcStats(stats_.health);
    stats.yangAtk = calcStats(stats_.yangAtk);
    stats.yinAtk = calcStats(stats_.yinAtk);
    stats.agility = calcStats(stats_.agility);
    stats.yangDef = calcStats(stats_.yangDef);
    stats.yinDef = calcStats(stats_.yinDef);
  }
};

const setLevel = () => {
  level.value = Math.ceil(level.value);
  if (level.value > maxLevel.value) level.value = maxLevel.value;
  if (level.value < 1) level.value = 1;
  setStats(friendSelect.value);
};

const setMaxLevel = () => {
  if (level.value > maxLevel.value) {
    level.value = maxLevel.value;
  }
  if (frToggle.value == "fr") maxLevel.value = 100;
  setStats(friendSelect.value);
};

const setRank = () => {
  if (frToggle.value == "fr") rankToggle.value = 5;
  setStats(friendSelect.value);
};

const setAwoken = () => {
  if (frToggle.value == "fr") awoken.value = 5;
  if (awoken.value <= 1) awoken.value = 1;
  setStats(friendSelect.value);
};

const setFriendship = () => {
  setStats(friendSelect.value);
};

const setHealth = () => {
  stats.health = Math.ceil(stats.health);
  if (stats.health >= MAX_HEALTH) stats.health = MAX_HEALTH;
  if (stats.health < 1) stats.health = 1;
};
const setYangAtk = () => {
  stats.yangAtk = Math.ceil(stats.yangAtk);
  if (stats.yangAtk >= MAX_STAT) stats.yangAtk = MAX_STAT;
  if (stats.yangAtk < 1) stats.yangAtk = 1;
};
const setYangDef = () => {
  stats.yangDef = Math.ceil(stats.yangDef);
  if (stats.yangDef >= MAX_STAT) stats.yangDef = MAX_STAT;
  if (stats.yangDef < 1) stats.yangDef = 1;
};
const setAgility = () => {
  stats.agility = Math.ceil(stats.agility);
  if (stats.agility >= MAX_STAT) stats.agility = MAX_STAT;
  if (stats.agility < 1) stats.agility = 1;
};
const setYinAtk = () => {
  stats.yinAtk = Math.ceil(stats.yinAtk);
  if (stats.yinAtk >= MAX_STAT) stats.yinAtk = MAX_STAT;
  if (stats.yinAtk < 1) stats.yinAtk = 1;
};
const setYinDef = () => {
  stats.yinDef = Math.ceil(stats.yinDef);
  if (stats.yinDef >= MAX_STAT) stats.yinDef = MAX_STAT;
  if (stats.yinDef < 1) stats.yinDef = 1;
};

const setValues = () => {
  setMaxLevel();
  setRank();
  setAwoken();
  setStats(friendSelect.value);
};

const calcStats = (stat) => {
  let denominator = 98.6;
  let flatBase = 14.4;
  let ratios = {};
  ratios["Level"] = 41;
  ratios["Enhancement"] = 8;
  ratios["Rank1"] = 1.5;
  ratios["Rank2"] = 1.5;
  ratios["Rank3"] = 1.5;
  ratios["Rank4"] = 1.5;
  ratios["Rank5"] = 1.5;
  ratios["Awk1"] = 3;
  ratios["Awk2"] = 3.9;
  ratios["Awk3"] = 5.5;
  ratios["Awk4"] = 7.46;
  ratios["LB1"] = 4;
  ratios["LB2"] = 5;
  ratios["LB3"] = 6.2;
  ratios["Green Heart"] = 2.95;
  ratios["Orange Heart"] = 2.95;
  ratios["Pink Heart"] = 2.95;

  let base = stat / denominator + flatBase;
  base = Math.round(base);

  let max = stat - base;

  let pred = base;

  pred += (max * (level.value - 1) * ratios["Level"]) / 100 / 100;
  pred += (max * level.value * ratios["Enhancement"]) / 100 / 100;
  pred += (max * rankToggle.value * ratios["Rank1"]) / 100;
  let awk1 = awoken.value >= 2 ? 1 : 0;
  let awk2 = awoken.value >= 3 ? 1 : 0;
  let awk3 = awoken.value >= 4 ? 1 : 0;
  let awk4 = awoken.value >= 5 ? 1 : 0;
  pred += (max * awk1 * ratios["Awk1"]) / 100;
  pred += (max * awk2 * ratios["Awk2"]) / 100;
  pred += (max * awk3 * ratios["Awk3"]) / 100;
  pred += (max * awk4 * ratios["Awk4"]) / 100;
  let lb1 = maxLevel.value >= 80 ? 1 : 0;
  let lb2 = maxLevel.value >= 90 ? 1 : 0;
  let lb3 = maxLevel.value >= 100 ? 1 : 0;
  pred += (max * lb1 * ratios["LB1"]) / 100;
  pred += (max * lb2 * ratios["LB2"]) / 100;
  pred += (max * lb3 * ratios["LB3"]) / 100;
  let friend1 = friendshipToggle.value >= 1 ? 1 : 0;
  let friend2 = friendshipToggle.value >= 2 ? 1 : 0;
  let friend3 = friendshipToggle.value >= 3 ? 1 : 0;
  pred += (max * friend1 * ratios["Green Heart"]) / 100;
  pred += (max * friend2 * ratios["Orange Heart"]) / 100;
  pred += (max * friend3 * ratios["Pink Heart"]) / 100;

  return Math.round(pred);
};

const saveStatsExpanded = () => {
  store.state.f.statsExpanded = statsExpanded.value;
};

const onSubmit = () => {
  if (!edit) {
    let newFriend = {
      name: friendSelect.value,
      level: level.value,
      maxLevel: maxLevel.value,
      rank: rankToggle.value,
      awoken: awoken.value,
      friendship: friendshipToggle.value,
      isFr: frToggle.value == "fr" ? true : false,
      stats: stats,
    };
    let payload = {
      friend: newFriend,
    };
    store.commit("addFriend", payload);

    level.value = 100;
    maxLevel.value = 100;
    stats.health = 1;
    stats.yangAtk = 1;
    stats.yinAtk = 1;
    stats.agility = 1;
    stats.yangDef = 1;
    stats.yinDef = 1;
    rankToggle.value = 5;
    awoken.value = 5;
    friendshipToggle.value = 3;
    frToggle.value = "normal";
  } else {
    store.state.f.editForm = false;
    let id = friend.value.row.id;
    let name = friend.value.row.name;
    let displayMode = friend.value.row.displayMode;
    let payload = {
      id: id,
      name: name,
      level: level.value,
      maxLevel: maxLevel.value,
      stats: stats,
      rank: rankToggle.value,
      awoken: awoken.value,
      friendship: friendshipToggle.value,
      isFr: frToggle.value == "normal" ? false : true,
      displayMode: displayMode,
    };
    store.commit("updateFriend", payload);
  }  
};

const showAddNotif = () => {
  //Add OK
  let added = friendOptions.value
    .map((f) => f.label)
    .includes(friendSelect.value);

  if (added) {
    $q.notify({
      type: "positive",
      color: "secondary",
      message: "Friend added",
      textColor: "white",
      multiLine: true,
      timeout: 1200,
    });
  }
};

const showUpdateNotif = () => {
  // TODO improve
  $q.notify({
    type: "positive",
    color: "secondary",
    message: `Friend updated`,
    textColor: "white",
    multiLine: true,
    timeout: 800,
  });
};
</script>
