<template>
  <q-form
    @submit="onSubmit"
    class="q-mt-sm q-mb-xs q-gutter-y-sm"
    align="center"
  >
    <div
      dark
      class="row wrap justify-center items-center q-col-gutter-md q-mb-md"
    >
      <div v-if="!edit" class="col-grow">
        <q-select
          v-if="!edit"
          autofocus
          name="storyCardSelect"
          filled
          dark
          v-model="storyCardModel"
          use-input
          hide-selected
          fill-input
          hide-bottom-space
          label="Pick a Story Card"
          input-debounce="0"
          :options="storyCardOptions"
          @filter="storyCardFilter"
          @input-value="setStoryCard"
          :rules="[(val) => storyCardOptions.includes(val)]"
          error-message="Please enter a valid story card name"
        >
          <template v-slot:option="scope">
            <q-item class="select-option" v-bind="scope.itemProps">
              <q-item-section class="select-option-icon" avatar>
                <q-img id="select-story-card-img" :src="scope.opt.imgPath" />
              </q-item-section>
              <q-space />
              <q-item-section>
                <q-item-label>{{ scope.opt.label }}</q-item-label>
              </q-item-section>
            </q-item>
          </template>

          <template v-slot:no-option>
            <q-item>
              <q-item-section class="text-grey"> No results </q-item-section>
            </q-item>
          </template>
        </q-select>
      </div>
      <div class="col-grow">
        <q-input
          name="level"
          v-model.number="level"
          type="number"
          label="Level"
          filled
          dark
          hide-bottom-space
          @update:model-value="setLevel"
          :rules="[(val) => (val > 0 && val <= 10) || val === 0]"
          error-message="Please enter a number between 0 and 10"
          min="0"
          max="10"
        />
      </div>
      <div class="mlb-toggle col-grow">
        <q-btn-toggle
          class="mlb-toggle"
          name="mlbToggle"
          v-model="mlbToggle"
          align-items="center"
          no-caps
          spread
          toggle-color="primary"
          color="white"
          text-color="black"
          :readonly="level >= 10"
          :options="[
            { label: '⠀⠀Normal⠀⠀', value: 'normal' },
            { label: '⠀⠀MLB⠀⠀', value: 'mlb' },
          ]"
        />
      </div>
      <div v-if="!edit" class="col-grow">
        <q-input
          v-if="!edit"
          name="quantity"
          v-model.number="quantity"
          type="number"
          label="Quantity"
          filled
          dark
          hide-bottom-space
          @update:model-value="setQuantity"
          :rules="[(val) => val > 0 && val < 100]"
          error-message="Please enter a number between 1 and 99"
          min="1"
          max="99"
        />
      </div>
    </div>

    <div v-if="!edit" align="center">
      <q-btn
        label="Submit"
        type="submit"
        color="primary"
        @click="showAddNotif"
        class="q-mr-md"
      />
      <q-btn
        @click="$emit('close', false)"
        label="Cancel"
        color="white"
        flat
        class="q-ml-md"
      />
    </div>
    <div v-else align="center">
      <q-btn
        label="Confirm"
        type="submit"
        color="primary"
        v-close-popup
        @click="showUpdateNotif"
      />
    </div>
  </q-form>
</template>

<style scoped>
#select-story-card-img {
  height: 45px;
  width: 85px;
}
.select-option {
  padding: 0px 4px;
}
.select-option-icon {
  padding: 0px;
  margin-right: 12px;
}
.q-input {
  min-width: 216px;
}
</style>

<script setup>
// @ is an alias to /srcimport { defineProps, watch } from "vue";
import { toRefs, ref, computed } from "@vue/reactivity";
import { defineProps, onMounted } from "vue";
import { useStore } from "vuex";
import { useQuasar } from "quasar";

const $q = useQuasar();
const store = useStore();

const props = defineProps({
  story_card: Object,
});
const { story_card } = toRefs(props);
const edit = story_card.value == null ? false : true;

const storyCardSelect = ref(null);
const storyCardsObj = ref(null);
const storyCardOptions = ref(null);

const level = ref(0);
if (edit) {
  level.value = story_card.value.row.level;
}

const mlbToggle = ref("normal");
if (edit) {
  if (story_card.value.row.isMlb) {
    mlbToggle.value = "mlb";
  }
}

const quantity = ref(1);

const storyCardItems = ref([]);

const storyCardModel = ref("");

const getstoryCardItems = () => {
  if (!store.state.sc.isLoadedStoryCardData) {
    storyCardsObj.value = store.dispatch("getStoryCardsData");
  } else storyCardsObj.value = store.getters.getStoryCardData;

  for (const [key, value] of Object.entries(storyCardsObj.value)) {
    key;
    storyCardItems.value.push({
      label: value.name,
      imgPath: getImgPath(value.name),
    });
  }
  storyCardOptions.value = storyCardItems.value;
};
onMounted(getstoryCardItems);

const getImgPath = (name) => {
  try {
    return require("../assets/story-cards-thumbs/".concat(
      name
        .toLowerCase()
        .replaceAll("!", "")
        .replaceAll("?", "")
        .replaceAll("'", "")
        .replaceAll(",", ""),
      ".png"
    ));
  } catch (e) {
    console.log(e);
  }
};

const storyCardFilter = (val, update) => {
  setTimeout(() => {
    update(
      () => {
        if (val === "") {
          storyCardOptions.value = storyCardItems.value;
        } else {
          const needle = val.toLocaleLowerCase();
          storyCardOptions.value = storyCardItems.value.filter(
            (v) => v.label.toLocaleLowerCase().indexOf(needle) > -1
          );
        }
      },
      (ref) => {
        if (val !== "" && ref.options.length > 0) {
          ref.setOptionIndex(-1); // reset optionIndex in case there is something selected
          ref.moveOptionSelection(1, true); // focus the first selectable option and do not update the input-value
        }
      }
    );
  }, 200);
};

const setStoryCard = (val) => {
  storyCardSelect.value = val;
};

const setLevel = () => {
  level.value = Math.ceil(level.value);
  if (level.value < 0) level.value = 0;
  if (level.value > 10) level.value = 10;
  if (level.value == 10) mlbToggle.value = "mlb";
};

const setQuantity = () => {
  quantity.value = Math.ceil(quantity.value);
  if (quantity.value < 1) quantity.value = 1;
  if (quantity.value > 99) quantity.value = 99;
};

const onSubmit = () => {
  if (!edit) {
    let newStoryCard = {
      name: storyCardSelect.value,
      level: level.value,
      isMlb: mlbToggle.value == "mlb" ? true : false,
    };
    let payload = {
      storyCard: newStoryCard,
      quantity: quantity.value,
    };
    store.commit("addStoryCard", payload);

    level.value = 0;
    mlbToggle.value = "normal";
    quantity.value = 1;
  } else {   
    store.state.sc.editForm = false;
    let id = story_card.value.row.id;
    let name = story_card.value.row.name;
    let displayMode = story_card.value.row.displayMode;
    let payload = {
      id: id,
      name: name,
      level: level.value,
      isMlb: mlbToggle.value == "mlb" ? true : false,
      displayMode: displayMode,
    };
    store.commit("updateStoryCard", payload);
  }
};

const showAddNotif = () => {
  //Add OK
  let added = storyCardOptions.value
    .map((sc) => sc.label)
    .includes(storyCardSelect.value);

  if (added) {
    $q.notify({
      type: "positive",
      color: "secondary",
      message:
        quantity.value == 1
          ? `${quantity.value} Story Card added`
          : `${quantity.value} Story Cards added`,
      textColor: "white",
      multiLine: true,
      timeout: 1200,
    });
  }
};

const changed = computed(() => {
  if (
    (story_card.value.row.isMlb ? "mlb" : "normal") == mlbToggle.value &&
    story_card.value.row.level == level.value
  )
    return false;
  else return true;
});

const showUpdateNotif = () => {
  //Update OK
  if (changed.value) {
    $q.notify({
      type: "positive",
      color: "secondary",
      message: `Story Card updated`,
      textColor: "white",
      multiLine: true,
      timeout: 800,
    });
  }
  //Update FAIL
  else {
    $q.notify({
      type: "info",
      color: "primary",
      message: `No changes to Story Card`,
      textColor: "white",
      multiLine: true,
      timeout: 800,
    });
  }
};
</script>
