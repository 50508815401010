<template>
  <q-btn
    @click.prevent="audio.isPlaying ? pause(audio) : play(audio)"
    class="btn"
    round
    color="amber"
    glossy
    text-color="black"
    icon="music_note"
  />
  <div v-for="n in 6" :key="n">
    <MarisadSpin />
  </div>
</template>

<style scoped>
.btn {
  position: fixed;
  bottom: 9vh;
  right: 2vw;
  z-index: 1;
}
</style>

<script>
// @ is an alias to /src
import MarisadSpin from "@/components/MarisadSpin.vue";

export default {
  name: "Other",
  components: {
    MarisadSpin,
  },
  data: function () {
    return {
      audio: {
        file: new Audio(require("../assets/marisad-spin.mp3")),
        isPlaying: false,
      }
    }
  },
  methods: {
    play(audio) {
      console.log(audio.file);
      audio.isPlaying = true;
      audio.file.play();
    },
    pause(audio) {
      console.log(audio.file);
      audio.isPlaying = false;
      audio.file.pause();
    },
  },
};
</script>
