<template>
  <div class="q-pa-none">
    <q-card
      class="story-card-card"
      @mouseover="mouseHovering"
      @mouseleave="mouseLeft"
      @click="clicked"
      @mouseup="mouseUp"
      ><!-- mouseup for mobile -->
      <q-img :src="smallImgPath">
        <div
          v-if="hovered && active && display_toggle != null"
          class="buttons-div"
          on-right
        >
          <q-btn
            name="update"
            icon="mdi-pencil-box-outline"
            class="buttons"
            color="secondary"
            @mousedown.stop="editStoryCard = true"
          >
          </q-btn>
          <q-btn
            name="delete"
            icon="mdi-trash-can-outline"
            class="buttons"
            color="negative"
            @mousedown.stop="showDeleteNotif"
          >
          </q-btn>
        </div>
        <div id="artist-info" v-if="hovered && active" on-left>
          <div class="text-caption">
            Original art by
            <q-btn
              outline
              rounded
              size="xs"
              name="view"
              icon="mdi-eye"
              color="blue-grey-1"
              @mousedown.stop="getImgPath"
            />
          </div>
          <div class="text-body2">{{ story_card.row.artist }}</div>
        </div>
        <q-dialog v-model="displayFullImage" full-width auto-close>
          <q-card class="q-pa-xs" dark>
            <q-img :src="imgPath" />
          </q-card>
        </q-dialog>
        <q-dialog v-model="editStoryCard">
          <q-card id="form-dialog" class="q-px-sm q-pb-sm">
            <story-cards-form :story_card="story_card"></story-cards-form>
          </q-card>
        </q-dialog>
        <div
          v-if="story_card.row.displayMode"
          class="bottom-display absolute-bottom text-subtitle2"
        >
          <div class="fit">
            <div class="row justify-between">
              <div class="stat-icon">
                <q-img :src="iconPath1">
                  <q-tooltip
                    class="bg-primary text-body2"
                    transition-show="fade"
                    transition-hide="fade"
                    :delay="150"
                    :hide-delay="250"
                    :offset="[0, 1]"
                  >
                    {{ computedStats[0].stat }}
                  </q-tooltip>
                </q-img>
                {{ computedStats[0].value }}
              </div>
              <div class="stat-icon">
                <q-img :src="iconPath2">
                  <q-tooltip
                    class="bg-primary text-body2"
                    transition-show="fade"
                    transition-hide="fade"
                    :delay="150"
                    :hide-delay="250"
                    :offset="[0, 1]"
                  >
                    {{ computedStats[1].stat }}
                  </q-tooltip>
                </q-img>
                {{ computedStats[1].value }}
              </div>
              <div>Lv. {{ story_card.row.level }}</div>
            </div>
            <q-separator inset dark size=".1rem" />
            <div class="row justify-between no-wrap items-center q-gutter-xs">
              <div class="card-name no-wrap ellipsis">
                {{ story_card.row.name }}
                <q-tooltip
                  v-if="itemWidth <= 310"
                  class="bg-primary text-body2"
                  transition-show="fade"
                  transition-hide="fade"
                  :delay="550"
                  :hide-delay="250"
                  :offset="[0, 1]"
                >
                  {{ story_card.row.name }}
                </q-tooltip>
              </div>
              <div class="rarity-icon col-2">
                {{ story_card.row.rarity }}
                <q-icon name="mdi-star" />
              </div>
              <div v-if="story_card.row.isMlb">
                <q-icon
                  class="mlb-icon col-2 q-mb-xs"
                  name="mdi-cards-diamond"
                />
              </div>
              <div v-else>
                <q-icon
                  class="mlb-icon col-2 q-mb-xs"
                  name="mdi-cards-diamond-outline"
                />
              </div>
            </div>
          </div>
        </div>
        <div v-else class="bottom-display absolute-bottom text-subtitle2 flex">
          <div
            v-if="itemWidth >= 400"
            class="desc"
            v-html="story_card.row.desc"
          ></div>
          <div
            v-else-if="itemWidth <= 300"
            class="densest_desc"
            v-html="story_card.row.desc.replaceAll('<br/>', ' ')"
          ></div>
          <div
            v-else
            class="dense_desc"
            v-html="story_card.row.desc.replaceAll('<br/>', ' ')"
          ></div>
        </div>
      </q-img>
    </q-card>
  </div>
</template>

<style scoped>
#form-dialog {
  background-color: #203250f2;
  width: max(280px, 28vw);
  margin: 0px 0px;
}
#artist-info {
  margin-bottom: 0px;
  padding: 4px 8px 2px 8px;
  border-radius: 4px;
}
#artist-info .text-caption {
  color: #aaaaaa;
  font-weight: 350;
  margin-bottom: -2px;
}
#artist-info .q-btn {
  padding: 2px 6px;
  margin-left: 4px;
}
.bottom-display {
  padding: 8px 12px 8px 12px;
}
.story-card-card {
  background-color: #25395b;
}
.stat-icon .q-img {
  height: 1.5rem;
  width: 1.5rem;
  margin-bottom: 4px;
}
.rarity-icon {
  font-size: 1.1em;
}
.rarity-icon .q-icon {
  font-size: 1.2em;
  margin-bottom: 3px;
  color: #ffd740;
}
.mlb-icon {
  font-size: 1.5em;
}
.buttons-div {
  position: absolute;
  padding: 0;
  right: 2px;
  top: 5px;
  background-color: transparent;
}
.buttons {
  padding: 10px 10px;
  margin: 0px 3px;
}
.desc {
  font-size: 0.8rem;
  font-weight: 400;
}
.dense_desc {
  font-size: 0.76rem;
  line-height: 1.6;
  font-weight: 400;
}
.densest_desc {
  font-size: 0.72rem;
  line-height: 1.6;
  font-weight: 400;
}
</style>

<style>
.element-no-element {
  color: rgb(195, 195, 195);
  font-weight: 700;
}
.element-wood {
  color: rgb(135, 196, 108);
  font-weight: 700;
}
.element-water {
  color: rgb(113, 216, 255);
  font-weight: 700;
}
.element-fire {
  color: rgb(255, 88, 88);
  font-weight: 700;
}
.element-earth {
  color: rgb(219, 163, 116);
  font-weight: 700;
}
.element-metal {
  color: rgb(255, 213, 0);
  font-weight: 700;
}
.element-star {
  color: rgb(216, 206, 114);
  font-weight: 700;
}
.element-sun {
  color: rgb(255, 255, 45);
  font-weight: 700;
}
.element-moon {
  color: rgb(204, 146, 245);
  font-weight: 700;
}
.effect-down {
  color: #49b3ff;
  font-weight: 700;
}
.effect-up {
  color: #ff895b;
  font-weight: 700;
}
.sp-up {
  color: #1cff18;
  font-weight: 700;
}
.keyword {
  color: #ffeb89;
  font-weight: 700;
}
</style>

<script setup>
import { defineProps, watch } from "vue";
import { toRefs, ref, computed } from "@vue/reactivity";
import { useStore } from "vuex";
import { onMounted, onUnmounted, onUpdated } from "@vue/runtime-core";
import { useQuasar } from "quasar";

import StoryCardsForm from "./StoryCardsForm.vue";

const store = useStore();
const $q = useQuasar();

const props = defineProps({
  story_card: Object,
  display_toggle: Object,
});
const { story_card } = toRefs(props);
const { display_toggle } = toRefs(props);

const imgPath = ref("");
const smallImgPath = ref("");
const iconPath1 = ref("");
const iconPath2 = ref("");

const displayFullImage = ref(false);

const hovered = ref(false);

const restore = ref(false);

const itemWidth = ref(0);

const handleResize = () => {
  try {
    let card = document.getElementsByClassName("story-card-card")[0];
    itemWidth.value = card.clientWidth;
  } catch (e) {
    console.log("No item to resize");
  }
};

const mouseHovering = () => {
  if (!(navigator.maxTouchPoints >= 1)) {
    //console.log("hover");
    hovered.value = true;
    store.state.sc.currentActiveRow = story_card.value.rowIndex;
  }
};
const mouseLeft = () => {
  //console.log("left");
  hovered.value = false;
  store.state.sc.currentActiveRow = -1;
};
const mouseUp = () => {
  if (navigator.maxTouchPoints >= 1) {
    //console.log("up");
    hovered.value = true;
    store.state.sc.currentActiveRow = story_card.value.rowIndex;
  }
};
const clicked = () => {
  story_card.value.row.displayMode = !story_card.value.row.displayMode;
  let payload = {
    id: story_card.value.row.id,
    displayMode: story_card.value.row.displayMode,
  };
  if (display_toggle.value != null) display_toggle.value.displayMode = null;
  store.commit("updateAllStoryCardsDisplayMode", null);
  store.commit("updateStoryCardDisplayMode", payload);
};

const active = computed(() => {
  return story_card.value.rowIndex == store.state.sc.currentActiveRow;
});

onMounted(() => {
  handleResize();
  window.addEventListener("resize", () => {
    handleResize();
  });
});
onMounted(() => {
  handleResize();
  window.addEventListener("resize", () => {
    handleResize();
  });
});
onUnmounted(() => {
  handleResize();
  window.addEventListener("resize", () => {
    handleResize();
  });
});

const getImgPath = () => {
  try {
    displayFullImage.value = true;
    imgPath.value = require("../assets/story-cards-img/".concat(
      story_card.value.row.name
        .toLowerCase()
        .replaceAll("!", "")
        .replaceAll("?", "")
        .replaceAll("'", "")
        .replaceAll(",", ""),
      ".png"
    ));
  } catch (e) {
    console.log(e);
  }
};

const getSmallImgPath = () => {
  try {
    smallImgPath.value = require("../assets/story-cards-small-img/".concat(
      story_card.value.row.name
        .toLowerCase()
        .replaceAll("!", "")
        .replaceAll("?", "")
        .replaceAll("'", "")
        .replaceAll(",", ""),
      ".png"
    ));
  } catch (e) {
    console.log(e);
  }
  try {
    iconPath1.value = require("../assets/stats-icons/".concat(
      computedStats.value[0].stat,
      ".png"
    ));
  } catch (e) {
    console.log(e);
  }
  try {
    iconPath2.value = require("../assets/stats-icons/".concat(
      computedStats.value[1].stat,
      ".png"
    ));
  } catch (e) {
    console.log(e);
  }
};

onMounted(getSmallImgPath);
onUpdated(getSmallImgPath);

const editStoryCard = ref(false);

const deleteStoryCard = () => {
  store.commit("deleteStoryCards");
};

const restoreStoryCard = () => {
  store.commit("restoreStoryCards");
};

const computedStats = computed(() => {
  let rawStats = story_card.value.row.stats;
  let cleanStats = [];

  Object.entries(rawStats).forEach(([key, val]) => {
    let cleanStat = {};

    cleanStat.stat = key;
    cleanStat.value = val;

    cleanStats.push(cleanStat);
  });
  return cleanStats;
});

watch(editStoryCard, (newVal) => {
  if (newVal) {
    store.state.sc.editForm = editStoryCard;
  }
});

const showDeleteNotif = () => {
  let deleteCount = store.state.sc.storyCardsToDelete.length + 1;
  store.commit("prepareStoryCardDeletion", story_card.value.row);
  $q.notify({
    progress: true,
    group: "deleteNotif",
    type: "negative",
    message:
      deleteCount >= 2
        ? `${deleteCount} Story Cards deleted`
        : `${deleteCount} Story Card deleted`,
    textColor: "white",
    multiLine: true,
    timeout: 4400,
    onDismiss: () => {
      if (!restore.value) deleteStoryCard();
      restore.value = false;
    },
    actions: [
      {
        label: deleteCount >= 2 ? "Restore All" : "Restore",
        color: "white",
        handler: () => {
          restore.value = true;
          restoreStoryCard();
        },
      },
      {
        label: "Confirm",
        color: "white",
        handler: () => {
          deleteStoryCard();
        },
      },
    ],
  });
};
</script>