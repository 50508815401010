
const myStorage = window.localStorage;

const comparator = (payload) => {
  let a = payload.a
  let b = payload.b
  let sort = payload.sort
  if ("nameSort" in sort) {
    let nameSort = sort.nameSort
    if (nameSort === "d") {
      if (a.name > b.name) return 1;
      if (a.name < b.name) return -1;
    }
    if (nameSort === "a") {
      if (a.name < b.name) return 1;
      if (a.name > b.name) return -1;
    }
    return 0;
  }
  if ("awokenSort" in sort) {
    let awokenSort = sort.awokenSort
    if (awokenSort === "a") return b.awoken - a.awoken;
    if (awokenSort === "d") return a.awoken - b.awoken;
  }
  if ("levelSort" in sort) {
    let levelSort = sort.levelSort
    if (levelSort === "a") return b.level - a.level;
    if (levelSort === "d") return a.level - b.level;
  }
  if ("maxLevelSort" in sort) {
    let maxLevelSort = sort.maxLevelSort
    if (maxLevelSort === "a") return b.maxLevel - a.maxLevel;
    if (maxLevelSort === "d") return a.maxLevel - b.maxLevel;
  }
  if ("statSort" in sort && sort.statSort != null) {
    let stat = sort.statSort.stat
    let direction = sort.statSort.direction
    if (direction === "a") return b.stats[stat] - a.stats[stat];
    if (direction === "d") return a.stats[stat] - b.stats[stat];
  }
  return b.id - a.id
}

const loadFriendList = () => {
  if (!myStorage.getItem("friends_list")) {
    myStorage.setItem("friends_list", JSON.stringify([]));
  }
  return JSON.parse(
    myStorage.getItem("friends_list")
  );
}

const friends = {

  state: () => ({
    _calls: {},
    currentId: myStorage.getItem("lastFriendId"),
    newFriend: {
      id: 0,
      name: "",
      //rarity: "",
      level: 0,
      maxLevel: 0,
      awoken: 0,
      friendship: 0,
      isFr: "",
      stats: {},
      spellCards: {},
      displayMode: true, //true => default (name + stats)       
    },
    currentStats: {},
    currentSpellCards: {},
    currentDisplayMode: true,
    //currentRarity: "",
    friendList: [],
    currentSort: {
      filter: {
        frFilter: null,
        //rarityFilter: null,
      },
      primary_sort: {},
      secondary_sort: {},
    },
    friendData: [],
    isLoadedFriendList: false,
    isLoadedFriendData: false,
    friendsToDelete: [],
    currentToggleDisplayMode: null,
    currentActiveRow: -1,
    editForm: false,
    showForm: false,
    statsExpanded: true,
    history: []
  }),
  mutations: {
    _updateCalls(state, payload) {
      let endTime = performance.now()
      if (!state._calls[payload.fn]) state._calls[payload.fn] = {}
      state._calls[payload.fn]["amount"] = (state._calls[payload.fn]["amount"] || 0) + 1
      state._calls[payload.fn]["total_time"] = (state._calls[payload.fn]["total_time"] || 0) + (endTime - payload.startTime)
    },
    incrementFriendId(state) {
      myStorage.setItem("lastFriendId", ++state.currentId)
    },
    loadFriendList(state) {
      state.friendList = loadFriendList();
    },
    saveFriendList(state, payload) {
      /*       console.log("__SAVE")
            console.log(payload) */
      myStorage.setItem("friends_list", JSON.stringify(payload))
    },
    setLoadFriendList(state, payload) {
      state.isLoadedFriendList = payload
    },
    addFriend(state, payload) {
      if (!state.isLoadedFriendData) {
        console.log("Data not loaded yet!")
      }
      else {
        let tmp = loadFriendList();

        let _payload = {
          name: payload.friend.name,
          maxLevel: payload.friend.maxLevel,
        }

        this.commit("getSpellCards", _payload)
        payload.friend.spellCards = state.currentSpellCards

        //calculate stuff, get stuff, ...
        payload.friend.displayMode = true

        this.commit("incrementFriendId")
        payload.friend.id = state.currentId

        let new_friend = JSON.parse(JSON.stringify(payload.friend))
        tmp.push(new_friend);
        state.friendList.push(new_friend);
        this.commit("saveFriendList", tmp);
        this.commit("sortFriends", state.currentSort);
        state.history.push(new_friend);
        myStorage.setItem("history", JSON.stringify(state.history));
      }
    },
    deleteFriends(state) {
      state.friendsToDelete = []
    },
    restoreFriends(state) {
      let tmp = loadFriendList();
      tmp = tmp.concat(state.friendsToDelete);
      state.friendsToDelete = []
      this.commit("saveFriendList", tmp);
      this.commit("sortFriends", state.currentSort);
    },
    prepareFriendDeletion(state, payload) {
      let tmp = loadFriendList();
      if (!state.friendsToDelete.includes(payload)) {
        tmp = tmp.filter(
          (e) => { return e.id != payload.id });
        state.friendList = state.friendList.filter(
          (e) => { return e.id != payload.id });
        state.friendsToDelete.push(payload);
      }
      this.commit("saveFriendList", tmp);
    },
    getFriendById(state, payload) {
      let f = state.friendList.find((e) => e.id == payload)
      state.newFriend = f
    },
    updateFriend(state, payload) {
      let tmp = loadFriendList();
      let f = tmp.find((e) => e.id == payload.id)
      f.level = payload.level
      f.maxLevel = payload.maxLevel
      f.stats = payload.stats
      f.isFr = payload.isFr
      f.rank = payload.rank
      f.awoken = payload.awoken
      f.friendship = payload.friendship
      this.commit("getSpellCards", payload)
      f.spellCards = state.currentSpellCards

      f.displayMode = payload.displayMode
      this.commit("saveFriendList", tmp);
      this.commit("sortFriends", state.currentSort);
      state.history.push(f);
      myStorage.setItem("history", JSON.stringify(state.history));
    },
    updateFriendStoryCards(state, payload) {
      let tmp = loadFriendList();
      let f = tmp.find((e) => e.id == payload.id)
      f.spellCards = payload.spellCards
      this.commit("saveFriendList", tmp);
    },
    getSpellCards(state, payload) {
      let maxLevel = payload.maxLevel;
      let tmpSpellCards
      if (payload.spellCards) {
        tmpSpellCards = payload.spellCards
        if (maxLevel >= 80) {
          tmpSpellCards.sc3 = payload.spellCards.sc3
        }
        if (maxLevel >= 90) {
          tmpSpellCards.sc4 = payload.spellCards.sc4
        }
        if (maxLevel >= 100) {
          tmpSpellCards.lw = payload.spellCards.lw
        }
      } else {
        tmpSpellCards = { sc1: null, sc2: null }
        if (maxLevel >= 80) {
          tmpSpellCards.sc3 = null
        }
        if (maxLevel >= 90) {
          tmpSpellCards.sc4 = null
        }
        if (maxLevel >= 100) {
          tmpSpellCards.lw = null
        }
      }
      state.currentSpellCards = tmpSpellCards
      console.log(state.currentSpellCards)
    },
    sortFriends(state, payload) {
      state.currentSort = payload
      /*       console.log("__SORT")
            console.log(JSON.stringify(payload)) */
      state.friendList = loadFriendList();
      // Filters
      if ("frFilter" in payload.filter) {
        this.commit("filterByFr", payload.filter.frFilter)
      }
      // Sorts
      let l = state.friendList
      l.sort((a, b) => {
        let ps = comparator({ a: a, b: b, sort: payload.primary_sort })
        if (ps == 0) {
          let ss = comparator({ a: a, b: b, sort: payload.secondary_sort })
          return ss
        }
        else {
          return ps
        }
      })
    },
    filterByFr(state, payload) {
      let l = state.friendList
      if (payload === "Fantasy Rebirth") {
        l = l.filter(e => e.isFr == true)
      }
      else if (payload === "Normal") {
        l = l.filter(e => e.isFr == false)
      }
      else {
        l = l.filter(e => e)
      }
      state.friendList = l
    },
    updateFriendDisplayMode(state, payload) {
      this.commit("getFriendById", payload.id)
      state.newFriend.displayMode = payload.displayMode
    },
    updateAllFriendsDisplayMode(state, payload) {
      state.currentToggleDisplayMode = payload
      if (payload != null) {
        myStorage.setItem('display_mode', payload)
        let tmp = loadFriendList();
        tmp.map((f) => f.displayMode = !payload)
        this.commit("saveFriendList", tmp)
        state.friendList.map((f) => f.displayMode = !payload)
      }
    }
  },
  actions: {
    getFriendsData({ state }) {
      let fd = require('../../../db.json').characters_data
      state.friendData = fd
      state.isLoadedFriendData = true; // commit?
    },
  },
  modules: {
  },
  getters: {
    getFriendData: state => state.friendData,
    getNewFriend: state => state.newFriend,
    getFriendList: state => state.friendList,
    getSavedFriendListLength: () => loadFriendList().length,
  }
}

export default friends;