<template>
  <q-layout
    id="main-layout"
    view="hHh lpR fff"
    @mousedown="resetCurrentActiveRow"
  >
    <q-header>
      <q-toolbar>
        <q-btn flat label="LostWord Optimizer" to="/" exact />
        <q-space />
        <q-tabs
          v-model="tab"
          shrink
          stretch
          inline-label
          class="desktop-display"
        >
          <q-route-tab
            name="friends"
            label="Friends"
            icon="img:friend-icon.png"
            to="/friends"
            exact
          />
          <q-route-tab
            name="story_cards"
            label="Story Cards"
            icon="img:story-cards-icon.png"
            to="/storycards"
            exact
          />
          <q-route-tab
            name="calculator"
            label="Calculator"
            icon="img:calculator-icon.png"
            to="/calculator"
            exact
          />
          <q-route-tab
            name="other"
            label="Other"
            icon="img:dots-icon.png"
            to="/other"
            exact
          />
        </q-tabs>
        <q-btn
          flat
          dense
          round
          @click="drawerRight = !drawerRight"
          aria-label="Menu"
          icon="menu"
          class="smartphone-display"
        />
      </q-toolbar>
    </q-header>

    <q-drawer
      v-model="drawerRight"
      side="right"
      no-swipe-open
      no-swipe-close
      bordered
      overlay
      :width="200"
    >
      <q-list>
        <q-item clickable to="/friends" exact>
          <q-item-section avatar>
            <q-icon name="img:friend-icon.svg" />
          </q-item-section>
          <q-item-section>
            <q-item-label>Friends</q-item-label>
          </q-item-section>
        </q-item>
        <q-item clickable to="/storycards" exact>
          <q-item-section avatar>
            <q-icon name="img:story-cards-icon.svg" />
          </q-item-section>
          <q-item-section>
            <q-item-label>Story Cards</q-item-label>
          </q-item-section>
        </q-item>
        <q-item clickable to="/calculator" exact>
          <q-item-section avatar>
            <q-icon name="img:calculator-icon.svg" />
          </q-item-section>
          <q-item-section>
            <q-item-label>Calculator</q-item-label>
          </q-item-section>
        </q-item>
        <q-item clickable to="/other" exact>
          <q-item-section avatar>
            <q-icon name="img:dots-icon.svg" />
          </q-item-section>
          <q-item-section>
            <q-item-label>Other</q-item-label>
            <q-item-label caption>W.I.P.</q-item-label>
          </q-item-section>
        </q-item>
      </q-list>
    </q-drawer>

    <q-page-container id="page">
      <!-- :style="backgroundImage"> -->
      <router-view />
    </q-page-container>

    <q-footer class="footer">
      <q-toolbar class="row justify-center">
        Touhou LostWord is officially licensed by Team Shanghai Alice. ©Team
        Shanghai Alice ©GOOD SMILE COMPANY, INC. / NextNinja Co., Ltd
        <br />
        This site is not affiliated with or endorsed by GOOD SMILE COMPANY.
        Images and data ©GOOD SMILE COMPANY, INC.
      </q-toolbar>
    </q-footer>
  </q-layout>
</template>

<style scoped>
#main-layout {
  background-color: #25395b;
}
.footer {
  padding: 1vh 0;
  max-height: 18%;
  text-align: center;
  font-size: 0.7rem;
}
@media only screen and (min-width: 900px) {
  .smartphone-display {
    display: none;
  }
}
@media only screen and (max-width: 900px) {
  .desktop-display {
    display: none;
  }
}
</style>

<script setup>
import { ref } from "@vue/reactivity";
import { useStore } from "vuex";
import { useQuasar } from "quasar";

const $q = useQuasar();
const store = useStore();

store.dispatch("getStoryCardsData");
store.dispatch("getFriendsData");
store.commit("loadFriendList")
store.commit("loadStoryCardList")
console.log(store.state.f.friendList)

/* const backgroundImage = ref({
  backgroundImage: `url(${require("@/assets/background-img/winter_hakurei_shrine_loop_c.jpg")})`,
}); */
const tab = ref("");

const drawerRight = ref(false);

const resetCurrentActiveRow = () => {
  if ($q.platform.has.touch >= 1) {
    store.state.sc.currentActiveRow = -1;
  }
};
</script>

