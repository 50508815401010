<template>
  <div id="bottom">
    <q-table
      id="table"
      dark
      grid
      grid-header
      title="Inventory"
      :rows="rows"
      :columns="columns"
      row-key="name"
      :filter="filter"
      v-model:pagination="pagination"
      :rows-per-page-options="[24, 48, 120]"
      rows-per-page-label="Friends per page :"
      no-data-label="Add a friend to your inventory"
      no-results-label="No Friend matches these criteria"
    >
      <template v-slot:header>
        <div id="header" class="row justify-between">
          <div class="col-xs-4 col-md-2">
            <div class="sort-btns-div">
              <q-btn
                class="sort-btn"
                v-bind:style="[
                  activeSort == 'name'
                    ? { 'background-color': '#eeeeee36', color: '#ffffff' }
                    : {},
                ]"
                v-model="nameSort"
                unelevated
                no-caps
                :padding="btnPad"
                @click="sortByName()"
              >
                <div>Name</div>
                <q-icon :name="nameArrowIcon"> </q-icon>
              </q-btn>
            </div>
          </div>
          <div class="col-xs-4 col-md-2">
            <div class="sort-btns-div">
              <q-btn
                class="sort-btn"
                v-bind:style="[
                  activeSort == 'level'
                    ? { 'background-color': '#eeeeee36', color: '#ffffff' }
                    : {},
                ]"
                v-model="levelSort"
                unelevated
                no-caps
                :padding="btnPad"
                @click="sortByLevel()"
              >
                <div>Level</div>
                <q-icon :name="levelArrowIcon"> </q-icon>
              </q-btn>
            </div>
          </div>
          <div class="col-xs-4 col-md-2">
            <div class="sort-btns-div">
              <q-btn
                class="sort-btn"
                v-bind:style="[
                  activeSort == 'maxLevel'
                    ? { 'background-color': '#eeeeee36', color: '#ffffff' }
                    : {},
                ]"
                v-model="maxLevelSort"
                unelevated
                no-caps
                :padding="btnPad"
                @click="sortByMaxLevel()"
              >
                <div>Max Level</div>
                <q-icon :name="maxLevelArrowIcon"> </q-icon>
              </q-btn>
            </div>
          </div>
          <div class="col-xs-4 col-md-2">
            <div class="sort-btns-div">
              <q-btn
                class="sort-btn"
                v-bind:style="[
                  frFilter != 'All'
                    ? { 'background-color': '#eeeeee36', color: '#ffffff' }
                    : {},
                ]"
                v-model="frFilter"
                unelevated
                no-caps
                :padding="btnPad"
                @click="filterByFR()"
              >
                <div>{{ frFilter }}</div>
                <q-icon :name="frIcon"> </q-icon>
              </q-btn>
            </div>
          </div>
          <div class="col-xs-4 col-md-2">
            <div class="sort-select">
              <q-select
                dark
                :dense="isMobile"
                clearable
                v-model="statSelect"
                label="Stat"
                :options="statOptions"
                options-dense
                filled
              >
              </q-select>
            </div>
          </div>
          <div class="col-xs-4 col-md-2">
            <div v-show="statSelect != null" class="sort-btns-div">
              <q-btn
                class="sort-btn"
                v-bind:style="[
                  activeSort == 'stat'
                    ? { 'background-color': '#eeeeee36', color: '#ffffff' }
                    : {},
                ]"
                v-model="statSort"
                unelevated
                no-caps
                :padding="btnPad"
                @click="sortByStat()"
              >
                <div>{{ statSelect != null ? statSelect.label : "" }}</div>
                <q-icon :name="statArrowIcon"> </q-icon>
              </q-btn>
            </div>
          </div>
          <div class="col-grow">
            <div class="display-toggle">
              <div>Display Spell Cards</div>
              <q-toggle
                name="toggle"
                v-model="displayMode"
                color="primary"
                keep-color
                dark
                @update:model-value="switchDisplayMode"
              />
            </div>
          </div>
        </div>
      </template>
      <template v-slot:top-right>
        <q-btn
          size="sm"
          text-color="white"
          icon="mdi-clipboard-text-clock-outline"
          @click="showHistory"
        >
          <q-tooltip>Dev Tools</q-tooltip>
        </q-btn>
        <q-btn
          class="q-mx-lg"
          size="sm"
          disable
          text-color="white"
          icon="mdi-delete-sweep-outline"
          @click="deleteHistory"
          ><q-tooltip>Dev Tools</q-tooltip></q-btn
        >
        <q-input
          dark
          dense
          debounce="300"
          v-model="filter"
          placeholder="Search"
        >
          <template v-slot:append>
            <q-icon name="search" />
          </template>
        </q-input>
      </template>
      <template v-slot:no-data>
        <div class="full-width row flex-center text-subtitle1 q-pa-sm">
          <div v-if="empty">
            <div>Add a Friend to your inventory</div>
          </div>
          <div v-else class="row flex-center q-gutter-md">
            <div>No Friend matches these criteria</div>
            <q-btn
              id="reset-btn"
              color="primary"
              no-caps
              outline
              @click="resetFilters()"
            >
              <div>Reset Filters</div>
              <q-icon name="mdi-backspace-outline"> </q-icon>
            </q-btn>
          </div>
        </div>
      </template>
      <template v-slot:item="props">
        <q-intersection
          class="q-pa-xs col-xs-12 col-sm-6 col-md-4 col-lg-3"
          once
        >
          <friend-item
            :display_toggle="displayModeObj"
            :friend="props"
          ></friend-item>
        </q-intersection>
      </template>
    </q-table>
  </div>
</template>

<style scoped>
#bottom {
  border-radius: 4px;
  background-color: #00000020;
  margin: 2vh 0 0 0;
  padding: 0 0.5vw;
}
#header {
  margin: 1vh 1vw;
}
#table {
  color: #fffff0;
}
.sort-select {
  margin: 5px;
  min-width: 15%;
}
.sort-btns-div {
  display: flex;
  margin: 5px;
  color: #ffffffb3;
  min-width: 15%;
}
.sort-btns-div .q-icon,
#reset-btn .q-icon {
  margin-left: 6px;
}
.sort-btn {
  border-radius: 4px;
  font-size: 0.9rem;
  width: 100%;
  background-color: #ffffff12;
}
.display-toggle {
  display: flex;
  align-items: center;
  margin-right: 5px;
  margin-left: 5px;
  min-width: max(96px, 16%);
  font-weight: 400;
  color: #fffffff0;
}
@media only screen and (max-width: 500px) {
  .sort-select {
    margin-right: 2px;
    margin-left: 2px;
  }
  .sort-btns-div {
    margin-right: 2px;
    margin-left: 2px;
  }
}
</style>

<script setup>
import { computed, onMounted, ref, watch } from "vue";
import { useStore } from "vuex";
import { useQuasar } from "quasar";
import FriendItem from "./FriendItem.vue";

const myStorage = window.localStorage;
const $q = useQuasar();

const store = useStore();

const delay = (time) => {
  return new Promise((resolve) => setTimeout(resolve, time));
};

const fillList = () => {
  if (!store.state.f.isLoadedFriendList && store.state.f.isLoadedFriendData) {
    store.commit("sortFriends", displayPayload.value);
    store.commit("setLoadFriendList", true);
  }
};

if (!myStorage.getItem("friends_list")) {
  myStorage.setItem("friends_list", "[]");
}
if (!store.state.f.isLoadedFriendData) {
  store.dispatch("getFriendsData");
}
for (let millisecs = 100; millisecs <= 500; millisecs += 500) {
  delay(millisecs).then(() => fillList());
  if (store.state.f.isLoadedFriendData) {
    continue;
  }
}

const columns = [
  {
    name: "name",
    required: true,
    label: "Name",
    align: "left",
    field: (row) => row.name,
    format: (val) => `${val}`,
    sortable: true,
  },
];

const rows = ref([]);

store.watch(
  () => store.getters.getFriendList,
  () => {
    rows.value = store.getters.getFriendList;
  }
);

const filter = ref("");

const displayPayload = ref({
  filter: {
    frFilter: "",
  },
  primary_sort: {},
  secondary_sort: {},
});

const activeSort = ref("");

const nameSort = ref("");
const nameArrowIcon = ref("");

const displayMode = ref(null);
if (!myStorage.getItem("display_mode")) myStorage.setItem("display_mode", null);

const sortByName = () => {
  // update ui
  console.time("sort");
  if (nameSort.value === "") {
    nameSort.value = "d";
    nameArrowIcon.value = "mdi-arrow-down";
    activeSort.value = "name";
  } else if (nameSort.value === "d") {
    nameSort.value = "a";
    nameArrowIcon.value = "mdi-arrow-up";
    activeSort.value = "name";
  } else if (nameSort.value === "a") {
    nameSort.value = "";
    nameArrowIcon.value = "";
    activeSort.value = "";
  }
  if (!("nameSort" in displayPayload.value.primary_sort)) {
    displayPayload.value.secondary_sort = displayPayload.value.primary_sort;
  }
  displayPayload.value.primary_sort = { nameSort: nameSort.value };
  store.commit("sortFriends", displayPayload.value);
  console.timeEnd("sort");
};

const levelSort = ref("");
const levelArrowIcon = ref("");
const sortByLevel = () => {
  // update ui
  if (levelSort.value === "") {
    levelSort.value = "a";
    levelArrowIcon.value = "mdi-arrow-up";
    activeSort.value = "level";
  } else if (levelSort.value === "a") {
    levelSort.value = "d";
    levelArrowIcon.value = "mdi-arrow-down";
    activeSort.value = "level";
  } else if (levelSort.value === "d") {
    levelSort.value = "";
    levelArrowIcon.value = "";
    activeSort.value = "";
  }
  if (!("levelSort" in displayPayload.value.primary_sort)) {
    displayPayload.value.secondary_sort = displayPayload.value.primary_sort;
  }
  displayPayload.value.primary_sort = { levelSort: levelSort.value };
  store.commit("sortFriends", displayPayload.value);
};

const maxLevelSort = ref("");
const maxLevelArrowIcon = ref("");
const sortByMaxLevel = () => {
  // update ui
  if (maxLevelSort.value === "") {
    maxLevelSort.value = "a";
    maxLevelArrowIcon.value = "mdi-arrow-up";
    activeSort.value = "maxLevel";
  } else if (maxLevelSort.value === "a") {
    maxLevelSort.value = "d";
    maxLevelArrowIcon.value = "mdi-arrow-down";
    activeSort.value = "maxLevel";
  } else if (maxLevelSort.value === "d") {
    maxLevelSort.value = "";
    maxLevelArrowIcon.value = "";
    activeSort.value = "";
  }
  if (!("maxLevelSort" in displayPayload.value.primary_sort)) {
    displayPayload.value.secondary_sort = displayPayload.value.primary_sort;
  }
  displayPayload.value.primary_sort = { maxLevelSort: maxLevelSort.value };
  store.commit("sortFriends", displayPayload.value);
};

const frFilter = ref("All");
const frIcon = ref("");
const filterByFR = () => {
  // update ui
  if (frFilter.value === "All") {
    frFilter.value = "Fantasy Rebirth";
    frIcon.value = "mdi-cards-diamond";
  } else if (frFilter.value === "Fantasy Rebirth") {
    frFilter.value = "Normal";
    frIcon.value = "mdi-cards-diamond-outline";
  } else if (frFilter.value === "Normal") {
    frFilter.value = "All";
    frIcon.value = "";
  }
  displayPayload.value.filter.frFilter = frFilter.value;
  store.commit("sortFriends", displayPayload.value);
};

const statSelect = ref(null);
const statOptions = [
  {
    label: "Health",
    stat: "health",
  },
  {
    label: "Yang ATK",
    stat: "yangAtk",
  },
  {
    label: "Yin ATK",
    stat: "yinAtk",
  },
  {
    label: "Agility",
    stat: "agility",
  },
  {
    label: "Yang DEF",
    stat: "yangDef",
  },
  {
    label: "Yin DEF",
    stat: "yinDef",
  },
];
watch(statSelect, () => {
  displayPayload.value.secondary_sort = displayPayload.value.primary_sort;
  if (statSelect.value == null) {
    activeSort.value = "";
    displayPayload.value.primary_sort = { statSort: null };
  } else {
    activeSort.value = "stat";
    statSort.value = "a";
    statArrowIcon.value = "mdi-arrow-up";
    displayPayload.value.primary_sort = {
      statSort: { stat: statSelect.value.stat, direction: statSort.value },
    };
  }
  store.commit("sortFriends", displayPayload.value);
});

const statSort = ref("a");
const statArrowIcon = ref("mdi-arrow-up");
const sortByStat = () => {
  // update ui
  if (statSort.value === "") {
    statSort.value = "a";
    statArrowIcon.value = "mdi-arrow-up";
    activeSort.value = "stat";
  } else if (statSort.value === "a") {
    statSort.value = "d";
    statArrowIcon.value = "mdi-arrow-down";
    activeSort.value = "stat";
  } else if (statSort.value === "d") {
    statSort.value = "";
    statArrowIcon.value = "";
    activeSort.value = "";
  }
  if (!("statSort" in displayPayload.value.primary_sort)) {
    displayPayload.value.secondary_sort = displayPayload.value.primary_sort;
  }
  displayPayload.value.primary_sort = {
    statSort: { stat: statSelect.value.stat, direction: statSort.value },
  };
  store.commit("sortFriends", displayPayload.value);
};

const resetFilters = () => {
  frFilter.value = "All";
  frIcon.value = "";
  statSelect.value = null;
  filter.value = "";

  displayPayload.value.filter = {
    frFilter: null,
    //rarityFilter: null,
  };
  store.commit("sortFriends", displayPayload.value);
};

const switchDisplayMode = () => {
  store.commit("updateAllFriendsDisplayMode", displayMode.value);
};

store.watch(
  () => store.state.f.currentToggleDisplayMode,
  () => {
    displayMode.value = store.state.f.currentToggleDisplayMode;
  }
);

const updateUi = () => {
  /*       timeEnd(); */
  let startTime = performance.now();
  rows.value = store.getters.getFriendList;
  //console.log(store.state.f.friendList);
  displayPayload.value = store.state.f.currentSort;
  let f = store.state.f.currentSort.filter;
  let ps = store.state.f.currentSort.primary_sort;
  if ("frFilter" in f) {
    frFilter.value = f.frFilter;
    if (frFilter.value === "FR") {
      frFilter.value = "FR";
      frIcon.value = "mdi-cards-diamond";
    } else if (frFilter.value === "Non FR") {
      frFilter.value = "Non FR";
      frIcon.value = "mdi-cards-diamond-outline";
    } else {
      frFilter.value = "All";
      frIcon.value = "";
    }
  }
  if ("nameSort" in ps) {
    nameSort.value = ps.nameSort;
    if (nameSort.value === "d") {
      nameArrowIcon.value = "mdi-arrow-down";
      activeSort.value = "name";
    } else if (nameSort.value === "a") {
      nameArrowIcon.value = "mdi-arrow-up";
      activeSort.value = "name";
    } else {
      nameArrowIcon.value = "";
      activeSort.value = "";
    }
  } else if ("levelSort" in ps) {
    levelSort.value = ps.levelSort;
    if (levelSort.value === "d") {
      levelArrowIcon.value = "mdi-arrow-down";
      activeSort.value = "level";
    } else if (levelSort.value === "a") {
      levelArrowIcon.value = "mdi-arrow-up";
      activeSort.value = "level";
    } else {
      levelArrowIcon.value = "";
      activeSort.value = "";
    }
  } else if ("maxLevelSort" in ps) {
    maxLevelSort.value = ps.maxLevelSort;
    if (maxLevelSort.value === "d") {
      maxLevelArrowIcon.value = "mdi-arrow-down";
      activeSort.value = "maxLevel";
    } else if (maxLevelSort.value === "a") {
      maxLevelArrowIcon.value = "mdi-arrow-up";
      activeSort.value = "maxLevel";
    } else {
      maxLevelArrowIcon.value = "";
      activeSort.value = "";
    }
  } else if ("statSort" in ps) {
    statSelect.value = ps.statSort;
    //activeSort.value = "stat";
    if (statSort.value === "d") {
      statArrowIcon.value = "mdi-arrow-down";
      activeSort.value = "level";
    } else if (statSort.value === "a") {
      statArrowIcon.value = "mdi-arrow-up";
      activeSort.value = "level";
    } else {
      statArrowIcon.value = "";
      activeSort.value = "";
    }
  }
  if (myStorage.getItem("display_mode")) {
    displayMode.value = JSON.parse(myStorage.getItem("display_mode"));
  }
  let _payload = {
    fn: "FriendsList.updateUi",
    startTime: startTime,
  };
  store.commit("_updateCalls", _payload);

  store.state.f.history = JSON.parse(myStorage.getItem("history"));
};

const pagination = ref({
  rowsPerPage: 48,
});
if (myStorage.getItem("pagination")) {
  pagination.value = JSON.parse(myStorage.getItem("pagination"));
}

watch(pagination, (newValue, oldValue) => {
  if (newValue.rowsPerPage != oldValue.rowsPerPage) {
    let first = (oldValue.page - 1) * oldValue.rowsPerPage + 1;
    var newFirst = 1;
    for (let i = newValue.rowsPerPage; i <= first; i += newValue.rowsPerPage) {
      newFirst = i / newValue.rowsPerPage + 1;
    }
    pagination.value.page = newFirst;
  }
  myStorage.setItem("pagination", JSON.stringify(pagination.value));
});

onMounted(updateUi);

const empty = computed(() => {
  return store.getters.getSavedFriendListLength == 0;
});

const displayModeObj = ref({
  displayMode: displayMode,
});
const isMobile = ref($q.platform.is.mobile);
const btnPad = ref($q.platform.is.mobile ? "8px 0 8px 5px" : "16px 0 16px 5px");

const showHistory = () => {
  console.log(myStorage.getItem("history"));
};
const deleteHistory = () => {
  store.state.f.history = [];
  myStorage.setItem("history", "[]");
  console.log("History deleted");
};
</script>
