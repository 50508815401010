<template>
  <q-form
    @submit="onSubmit"
    class="fit row justify-center q-mt-sm q-mb-xs q-gutter-sm"
  >
    <q-card flat id="select-card" class="col-12">
      <q-select
        class="q-my-md"
        autofocus
        name="friendSelect"
        filled
        dark
        v-model="friendModel"
        use-input
        hide-selected
        hide-bottom-space
        fill-input
        label="Pick a Friend"
        input-debounce="0"
        :options="friendOptions"
        @filter="friendFilter"
        @input-value="setFriend"
        :rules="[(val) => friendOptions.includes(val)]"
        error-message="Please enter a valid friend name"
      >
        <template v-slot:option="scope">
          <q-item class="select-option q-pa-none" v-bind="scope.itemProps">
            <q-item-section class="select-option-thumbs col-2">
              <q-img :src="scope.opt.imgPath" />
            </q-item-section>
            <q-item-section class="q-px-xs col-5">
              <q-item-label lines="1" class="story-card-option-title">
                {{ scope.opt.label }}</q-item-label
              >
            </q-item-section>
            <q-space />
            <q-space />
            <q-item-section class="col-3" no-wrap>
              <q-item-label
                >Lv. {{ scope.opt.f.level }}/{{
                  scope.opt.f.maxLevel
                }}</q-item-label
              >
            </q-item-section>
          </q-item>
        </template>

        <template v-slot:no-option>
          <q-item>
            <q-item-section class="text-grey"> No results </q-item-section>
          </q-item>
        </template>
      </q-select>
    </q-card>
    <q-card flat id="skills-card" class="col-grow">
      <div class="column content-start">
        <div class="skills-label">Active skills</div>
        <q-option-group
          class="skills-checkbox"
          dark
          v-model="activeSkills"
          :options="skills"
          color="primary"
          type="checkbox"
        />
      </div>
    </q-card>
    <q-card flat id="boost-graze-card" class="col-grow">
      <div class="column justify-evenly q-gutter-y-md">
        <div class="column content-start">
          <div class="form-subtitle">Boost</div>
          <q-btn-toggle
            class="boost-toggle"
            name="boostToggle"
            v-model="boostToggle"
            unelevated
            no-caps
            spread
            padding="5px 10px"
            text-color="white"
            toggle-color="primary"
            @update:model-value="setBoost"
            :options="[
              {
                label: 0,
                value: 0,
              },
              { label: 1, value: 1 },
              { label: 2, value: 2 },
              { label: 3, value: 3 },
            ]"
          >
          </q-btn-toggle>
        </div>
        <div class="column content-start">
          <div class="form-subtitle">Graze</div>
          <q-btn-toggle
            class="graze-toggle"
            name="grazeToggle"
            v-model="grazeToggle"
            unelevated
            no-caps
            spread
            padding="5px 10px"
            text-color="white"
            toggle-color="primary"
            @update:model-value="setGraze"
            :options="[
              { label: 0, value: 0 },
              { label: 1, value: 1 },
              { label: 2, value: 2 },
              { label: 3, value: 3 },
            ]"
          >
          </q-btn-toggle>
        </div>
      </div>
    </q-card>
    <q-card flat id="spell-cards-card" class="col-grow">
      <div class="row items-center col-xs-12 col-lg-8 col-auto">
        <div class="column content-start">
          <div class="form-subtitle">Spell Cards</div>
          <q-btn-toggle
            id="spell-card-toggle"
            name="spellCardToggle"
            v-model="spellCardToggle"
            unelevated
            no-caps
            padding="5px 3px"
            text-color="white"
            toggle-color="primary"
            @update:model-value="setSpellCard"
            :options="spellCards"
          >
            <template v-slot:sc1>
              <q-img
                :src="getSpellCardImgPath('sc1')"
                height="min(28vw,240px)"
                width="min(14vw,120px)"
              >
              </q-img>
            </template>
            <template v-slot:sc2>
              <q-img
                :src="getSpellCardImgPath('sc2')"
                height="min(28vw,240px)"
                width="min(14vw,120px)"
              >
              </q-img>
            </template>
            <template v-slot:sc3>
              <q-img
                :src="getSpellCardImgPath('sc1')"
                height="min(28vw,240px)"
                width="min(14vw,120px)"
              >
              </q-img>
            </template>
            <template v-slot:sc4>
              <q-img
                :src="getSpellCardImgPath('sc2')"
                height="min(28vw,240px)"
                width="min(14vw,120px)"
              >
              </q-img>
            </template>
            <template v-slot:lw>
              <q-img
                :src="getSpellCardImgPath('lw')"
                height="min(28vw,240px)"
                width="min(14vw,120px)"
              >
              </q-img>
            </template>
          </q-btn-toggle>
        </div>
      </div>
    </q-card>
    <q-card flat id="mode-card" class="col-12">
      <div class="row items-center col-grow q-mr-sm">
        <div class="form-subtitle">Mode</div>
        <div class="mode-radio q-gutter-x-md">
          <q-radio dark v-model="modeChoice" label="Equip One" val="one" />
          <q-radio dark v-model="modeChoice" label="Equip All" val="all" />
          <q-radio dark v-model="modeChoice" label="Auto" val="auto" />
        </div>
      </div>
    </q-card>
    <q-card flat id="advanced-card" class="col-12">
      <q-expansion-item
        class="form-subtitle"
        v-model="showAdvancedParams"
        @update:model-value="saveShowAdvancedParams"
        dark
        label="Advanced parameters"
        switch-toggle-side
      >
        <calculator-advanced-params></calculator-advanced-params>
      </q-expansion-item>
    </q-card>

    <q-btn
      class="q-my-md q-py-md q-px-lg"
      size="md"
      label="Calculate"
      icon="mdi-calculator"
      type="submit"
      color="primary"
      v-close-popup
    />
  </q-form>
</template>

<style scoped>
.q-card {
  background-color: #00000020;
  padding: 1vh 2vh;
}
.select-option-thumbs {
  max-width: 55px;
  max-height: 55px;
}
.skills-checkbox {
  color: rgba(255, 255, 255, 0.85);
}
.mode-radio {
  color: rgba(255, 255, 255, 1);
}
.form-subtitle {
  color: rgba(255, 255, 255, 1);
  font-size: 1.2em;
  margin-right: 12px;
}
.skills-label {
  color: rgba(255, 255, 255, 1);
  font-size: 1.2em;
}
.q-btn-toggle {
  background-color: #30405c;
}
#spell-card-toggle {
  background-color: #00000000;
}
</style>

<script setup>
// @ is an alias to /src
import { computed, onMounted, ref } from "vue";
import { useStore } from "vuex";
import { useQuasar } from "quasar";

import CalculatorAdvancedParams from "../components/CalculatorAdvancedParams.vue";

const $q = useQuasar();
const store = useStore();

const activeSkills = ref([]);

const skills = computed(() => {
  let skills = [
    {
      label: "Skill 1",
      value: "skill1",
      disable: false,
    },
    {
      label: "Skill 2",
      value: "skill2",
      disable: true,
    },
    {
      label: "Skill 3",
      value: "skill3",
      disable: true,
    },
  ];

  if (friendModel.value != null) {
    let r = friendModel.value.f.rank;
    if (r <= 1) {
      skills[1].disable = true;
      skills[2].disable = true;
    } else if (r <= 3) {
      skills[1].disable = false;
      skills[2].disable = true;
    } else {
      skills[1].disable = false;
      skills[2].disable = false;
    }
  }
  return skills;
});

const spellCards = computed(() => {
  let spellCards = [
    { slot: "sc1", value: "sc1", disable: false },
    { slot: "sc2", value: "sc2", disable: false },
    { slot: "sc3", value: "sc3", disable: true },
    { slot: "sc4", value: "sc4", disable: true },
    { slot: "lw", value: "lw", disable: true },
  ];
  if (friendModel.value != null) {
    let maxLvl = friendModel.value.f.maxLevel;
    if (maxLvl <= 70) {
      spellCards[2].disable = true;
      spellCards[3].disable = true;
      spellCards[4].disable = true;
    } else if (maxLvl <= 80) {
      spellCards[2].disable = false;
      spellCards[3].disable = true;
      spellCards[4].disable = true;
    } else if (maxLvl <= 90) {
      spellCards[2].disable = false;
      spellCards[3].disable = false;
      spellCards[4].disable = true;
    } else {
      spellCards[2].disable = false;
      spellCards[3].disable = false;
      spellCards[4].disable = false;
    }
  }
  return spellCards;
});

const friendSelect = ref(null);
const friendsObj = ref(null);
const friendOptions = ref(null);
const friendItems = ref([]);
const friendModel = ref(null);

const boostToggle = ref(0);
const setBoost = () => {};

const grazeToggle = ref(0);
const setGraze = () => {};

const spellCardToggle = ref(0);
const setSpellCard = () => {};

const modeChoice = ref("auto");

const getfriendItems = () => {
  friendsObj.value = store.getters.getFriendList;

  for (const [key, value] of Object.entries(friendsObj.value)) {
    key;
    let dict = {
      label: value.name,
      f: value,
      imgPath: getImgPath(value.name),
      //TODO skills
    };
    friendItems.value.push(dict);
  }
  friendOptions.value = friendItems.value;
};
onMounted(getfriendItems);

const getImgPath = (name) => {
  try {
    return require("../assets/friends-thumbs/".concat(
      name
        .toLowerCase()
        .replaceAll("!", "")
        .replaceAll("?", "")
        .replaceAll("'", "")
        .replaceAll(",", ""),
      ".png"
    ));
  } catch (e) {
    console.log(e);
  }
};

const friendFilter = (val, update) => {
  setTimeout(() => {
    update(
      () => {
        if (val === "") {
          friendOptions.value = friendItems.value;
        } else {
          const needle = val.toLocaleLowerCase();
          friendOptions.value = friendItems.value.filter(
            (v) => v.label.toLocaleLowerCase().indexOf(needle) > -1
          );
        }
      },
      (ref) => {
        if (val !== "" && ref.options.length > 0) {
          ref.setOptionIndex(-1); // reset optionIndex in case there is something selected
          ref.moveOptionSelection(1, true); // focus the first selectable option and do not update the input-value
        }
      }
    );
  }, 200);
};

const setFriend = (val) => {
  friendSelect.value = val;
};

const showAdvancedParams = ref(false);
const saveShowAdvancedParams = () => {};

const getSpellCardImgPath = (name) => {
  if (friendModel.value != null) {
    return require("../assets/spell-cards-thumbs/" +
      friendModel.value.f.name.toLowerCase() +
      "_" +
      name +
      ".png");
  } else {
    return require("../assets/spell-cards-thumbs/" +
      "placeholder_" +
      name +
      ".png");
  }
};

const onSubmit = () => {
  console.log("Calculate");
  showTODONotif();
};

const showTODONotif = () => {
  $q.notify({
    type: "info",
    color: "info",
    message: `Todo calculate`,
    textColor: "white",
    multiLine: true,
    timeout: 500,
  });
};
</script>
