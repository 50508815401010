<template>
  <q-card flat class="row justify-center">WIP</q-card>
</template>

<style scoped>
</style>

<script setup>
// @ is an alias to /src
import { useStore } from "vuex";
import { useQuasar } from "quasar";

const $q = useQuasar();
const store = useStore();
$q;
store;
</script>
