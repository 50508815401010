<template>
  <div id="top">
    <q-fab
      v-model="showForm"
      @update:model-value="saveShowForm"
      color="secondary"
      square
      persistent
      class="q-mb-sm q-mt-sm"
      :label="addButtonLabel"
      label-style="font-size: 1.1rem"
      icon="keyboard_arrow_down"
      :padding="pad"
      ><!-- @click="showCalls" -->
    </q-fab>
    <q-slide-transition>
      <div v-show="showForm" autofocus>
        <friends-form :friend="null" v-on:close="closeForm"></friends-form>
      </div>
    </q-slide-transition>
  </div>
</template>

<style scoped>
#top {
  border-radius: 4px;
  background-color: #00000020;
  padding: 1vh 5vw;
  display: flex;
  flex-direction: column;
  justify-content: center;
}
</style>

<script setup>
// @ is an alias to /src
import { defineProps, ref, toRefs } from "vue";
import { useStore } from "vuex";
import { useQuasar } from "quasar";

import FriendsForm from "./FriendsForm.vue";

const $q = useQuasar();
const store = useStore();

const props = defineProps({
  addButtonLabel: String,
});
const { addButtonLabel } = toRefs(props);

const showForm = ref(store.state.f.showForm);

const saveShowForm = () => {
  store.state.f.showForm = showForm.value;
};

const closeForm = () => {
  showForm.value = false;
  saveShowForm();
};

/* const showCalls = () => {
  console.log(store.getters.getCalls);
}; */

const pad = ref($q.platform.is.desktop ? "md" : "sm");
</script>

